import classNames from 'classnames';

const HddIcon = (props: React.SVGProps<SVGSVGElement>) => {
  const { className = '', ...attributes } = props;
  const classes = classNames('hdd-icon', className);

  return (
    <svg
      width="100%"
      viewBox="0 0 180 180"
      fill="none"
      className={classes}
      {...attributes}
    >
      <circle
        cx="90"
        cy="90"
        r="89.1667"
        fill="url(#paint0_linear_2145_219)"
        stroke="url(#paint1_linear_2145_219)"
        strokeWidth="1.66667"
      />
      <path
        d="M113.153 126.744H67.8462L63.3164 122.214V58.7854L67.8462 54.2556H113.153L117.683 58.7854V122.214L113.153 126.744Z"
        stroke="#00B3FF"
        strokeWidth="2.64"
        strokeMiterlimit="10"
      />
      <path
        d="M83.3109 98.0765C85.5158 99.0309 87.9473 99.561 90.5029 99.561C100.511 99.561 108.625 91.4475 108.625 81.439C108.625 71.4305 100.511 63.3169 90.5029 63.3169C80.4944 63.3169 72.3809 71.4305 72.3809 81.439C72.3809 87.177 75.0483 92.2912 79.2099 95.6116"
        stroke="#00B3FF"
        strokeWidth="2.64"
        strokeMiterlimit="10"
      />
      <path
        d="M80.797 106.422C82.0843 104.277 85.067 90.4999 85.067 90.4999C85.067 90.4999 74.3145 99.6149 73.0272 101.761C71.7398 103.906 72.4355 106.689 74.5812 107.976C76.7268 109.264 79.5096 108.568 80.797 106.422Z"
        stroke="#00B3FF"
        strokeWidth="2.64"
        strokeMiterlimit="10"
      />
      <path
        d="M90.5012 85.9695C93.0034 85.9695 95.0317 83.9411 95.0317 81.439C95.0317 78.9368 93.0034 76.9084 90.5012 76.9084C87.9991 76.9084 85.9707 78.9368 85.9707 81.439C85.9707 83.9411 87.9991 85.9695 90.5012 85.9695Z"
        stroke="#00B3FF"
        strokeWidth="2.64"
        strokeMiterlimit="10"
      />
      <path
        d="M63.3164 117.683H81.4385L90.4995 108.622H117.683"
        stroke="#00B3FF"
        strokeWidth="2.64"
        strokeMiterlimit="10"
      />
      <path
        d="M92.7773 117.683H110.881"
        stroke="#00B3FF"
        strokeWidth="2.64"
        strokeMiterlimit="10"
      />
      <path
        d="M106.365 63.3169H110.877"
        stroke="#00B3FF"
        strokeWidth="2.64"
        strokeMiterlimit="10"
      />
      <path
        d="M70.123 63.3169H74.6348"
        stroke="#00B3FF"
        strokeWidth="2.64"
        strokeMiterlimit="10"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2145_219"
          x1="36.6667"
          y1="-7.77778"
          x2="134.444"
          y2="180"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#05638C" />
          <stop offset="0.589072" stopColor="#041922" />
          <stop offset="1" stopColor="#090C16" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2145_219"
          x1="43"
          y1="15.5"
          x2="146"
          y2="170"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1BBAFF" />
          <stop offset="0.440508" stopColor="#12182D" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default HddIcon;
