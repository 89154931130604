import classNames from 'classnames';

const HddDriveIcon = (props: React.SVGProps<SVGSVGElement>) => {
  const { className = '', ...attributes } = props;
  const classes = classNames('hdd-drive-icon', className);

  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      className={classes}
      {...attributes}
    >
      <circle
        cx="32"
        cy="32"
        r="31.4286"
        fill="url(#paint0_linear_2145_695)"
        fillOpacity="0.5"
        stroke="#222332"
        strokeWidth="1.14286"
      />
      <g clipPath="url(#clip0_2145_695)">
        <path
          d="M18.4648 17.8789H34.0569C34.3805 17.8789 34.6429 18.1412 34.6429 18.4648V38.851C34.6429 39.1746 34.3805 39.437 34.0569 39.437H18.4648C18.1412 39.437 17.8789 39.1746 17.8789 38.851V18.4648C17.8789 18.1412 18.1412 17.8789 18.4648 17.8789Z"
          stroke="#00B3FF"
          strokeWidth="1.75781"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M34.0431 35.8936H18.2246"
          stroke="#00B3FF"
          strokeWidth="1.75781"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M30.4177 32.0469H22.1035"
          stroke="#00B3FF"
          strokeWidth="1.75781"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M26.2598 40.0153V41.3971C26.2598 44.0155 28.3877 46.1344 31.004 46.1212L39.0544 46.0826C41.6522 46.0696 43.7513 43.9584 43.7513 41.3585V28.6582"
          stroke="#00B3FF"
          strokeWidth="1.75781"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M45.5317 21.3018H41.9746V24.8248H45.5317V21.3018Z"
          stroke="#00B3FF"
          strokeWidth="1.75781"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M41.3848 27.1671L42.565 28.3482H44.9419L46.1221 27.1671V24.8252H41.3848V27.1671Z"
          stroke="#00B3FF"
          strokeWidth="1.75781"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_2145_695"
          x1="32"
          y1="0"
          x2="32"
          y2="64"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#222332" />
          <stop offset="1" stopColor="#0C101C" />
        </linearGradient>
        <clipPath id="clip0_2145_695">
          <rect
            width="30"
            height="30"
            fill="white"
            transform="translate(17 17)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default HddDriveIcon;
