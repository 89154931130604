import classNames from 'classnames';

const BtcProgramsIcon = (props: React.SVGProps<SVGSVGElement>) => {
  const { className = '', ...attributes } = props;
  const classes = classNames('btc-programs-icon', className);

  return (
    <svg
      width="105"
      height="89.001"
      viewBox="0 0 105 89.001"
      fill="none"
      className={classes}
      {...attributes}
    >
      <path
        id="Union_18"
        data-name="Union 18"
        d="M-443,6653v-70h105v70Zm4-4h97v-62h-97Zm47.945-8.791v-3.735h-2.262v3.735h-4v-3.37h-4.263v-4h4.263v-16.578h-4.263v-4h4.263V6608h4v4.513h2.262V6608h4v4.533a6,6,0,0,1,5.51,5.973v2a5.969,5.969,0,0,1-1.517,3.981,6.013,6.013,0,0,1,1.517,4v2a5.968,5.968,0,0,1-5.51,5.978v3.75Zm-2.312-7.734h5.907a1.982,1.982,0,0,0,1.967-1.993v-2a1.981,1.981,0,0,0-1.967-1.992h-5.907Zm5.832-9.981a1.994,1.994,0,0,0,1.992-1.992v-2a1.994,1.994,0,0,0-1.992-1.992h-5.782v5.979ZM-436,6601v-4h91v4Zm15-8v-4h5v4Zm-6,0v-4h5v4Zm-7,0v-4h5v4Zm2-16v-4h83v4Zm11-9v-4h60v4Z"
        transform="translate(443 -6564)"
        fill="#00B3FF"
      />
    </svg>
  );
};

export default BtcProgramsIcon;
