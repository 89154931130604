import { ReactNode, useContext, useEffect } from 'react';
import { Context } from 'context/GlobalContext';
// import { Outfit } from 'next/font/google';
import localFont from 'next/font/local';
// const outfit = Outfit({ subsets: ['latin', 'latin-ext'] });

const outfit = localFont({
  src: [
    {
      path: '../../../fonts/outfit/outfit-v11-latin_latin-ext-regular.woff2',
      weight: '400',
      style: 'normal',
    },
    {
      path: '../../../fonts/outfit/outfit-v11-latin_latin-ext-600.woff2',
      weight: '600',
      style: 'normal',
    },
    {
      path: '../../../fonts/outfit/outfit-v11-latin_latin-ext-700.woff2',
      weight: '700',
      style: 'italic',
    },
  ],
});

interface Props {
  children: ReactNode;
}

const PageContentWrapper = (props: Props) => {
  const { children } = props;

  const { isCurtainActive } = useContext(Context);

  useEffect(() => {
    if (isCurtainActive) {
      // document.body.style.marginRight = `${
      //   window.innerWidth - document.body.clientWidth
      // }px`;
      document.body.style.overflow = 'hidden';

      return;
    }

    // document.body.style.marginRight = 'auto';
    document.body.style.overflow = 'auto';
  }, [isCurtainActive]);

  return (
    <main className={`app ${outfit.className}`}>
      <>{children}</>
    </main>
  );
};

export default PageContentWrapper;
