import classNames from 'classnames';

const InternetPortIcon = (props: React.SVGProps<SVGSVGElement>) => {
  const { className = '', ...attributes } = props;
  const classes = classNames('internet-port-icon', className);

  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      className={classes}
      {...attributes}
    >
      <circle
        cx="32"
        cy="32"
        r="31.4286"
        fill="url(#paint0_linear_2145_707)"
        fillOpacity="0.5"
        stroke="#222332"
        strokeWidth="1.14286"
      />
      <g clipPath="url(#clip0_2145_707)">
        <path
          d="M43.4288 17H20.5712C18.6022 17 17 18.6022 17 20.5712V43.4288C17 45.3978 18.6022 47 20.5712 47H43.4288C45.3978 47 47 45.3978 47 43.4288V20.5712C47 18.6022 45.3978 17 43.4288 17ZM45.2422 43.4288C45.2422 44.4287 44.4287 45.2422 43.4288 45.2422H20.5712C19.5713 45.2422 18.7578 44.4287 18.7578 43.4288V20.5712C18.7578 19.5713 19.5713 18.7578 20.5712 18.7578H43.4288C44.4287 18.7578 45.2422 19.5713 45.2422 20.5712V43.4288Z"
          fill="#00B3FF"
        />
        <path
          d="M41.4504 21.0781H22.5498C21.7382 21.0781 21.0781 21.7382 21.0781 22.5498V37.1351C21.0781 37.9465 21.7382 38.6068 22.5498 38.6068H25.3935V41.4504C25.3935 42.2621 26.0538 42.9221 26.8652 42.9221H37.1351C37.9465 42.9221 38.6068 42.2621 38.6068 41.4504V38.6068H41.4504C42.2621 38.6068 42.9221 37.9465 42.9221 37.1351V22.5498C42.9221 21.7382 42.2621 21.0781 41.4504 21.0781ZM41.1643 36.849H38.3207C37.5091 36.849 36.849 37.5093 36.849 38.3207V41.1643H27.1513V38.3207C27.1513 37.5091 26.491 36.849 25.6796 36.849H22.8359V22.8359H24.4486V26.4294C24.4486 26.9146 24.8423 27.3083 25.3275 27.3083C25.813 27.3083 26.2065 26.9146 26.2065 26.4294V22.8359H27.7848V26.4294C27.7848 26.9146 28.1785 27.3083 28.6637 27.3083C29.1492 27.3083 29.5426 26.9146 29.5426 26.4294V22.8359H31.1212V26.4294C31.1212 26.9146 31.5147 27.3083 32.0001 27.3083C32.4856 27.3083 32.879 26.9146 32.879 26.4294V22.8359H34.3979V26.4294C34.3979 26.9146 34.7914 27.3083 35.2768 27.3083C35.762 27.3083 36.1557 26.9146 36.1557 26.4294V22.8359H37.6743V26.4294C37.6743 26.9146 38.068 27.3083 38.5533 27.3083C39.0387 27.3083 39.4322 26.9146 39.4322 26.4294V22.8359H41.1643V36.849Z"
          fill="#00B3FF"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_2145_707"
          x1="32"
          y1="0"
          x2="32"
          y2="64"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#222332" />
          <stop offset="1" stopColor="#0C101C" />
        </linearGradient>
        <clipPath id="clip0_2145_707">
          <rect
            width="30"
            height="30"
            fill="white"
            transform="translate(17 17)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default InternetPortIcon;
