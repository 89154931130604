import { ReactNode } from 'react';

interface Props {
  header: string;
  subheader: string;
  description: ReactNode;
}

const PageError = (props: Props) => {
  const { header, subheader, description } = props;

  return (
    <div className="page-error">
      <h1 className="page-error__header">{header}</h1>
      <h4 className="page-error__subheader">{subheader}</h4>
      <p className="page-error__header-description">
        <>{description}</>
      </p>
    </div>
  );
};

export default PageError;
