import { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import { useTranslation } from 'next-i18next';
import { getFullUrl } from 'utils/Url';
import Link from 'next/link';

const CookiesConsent = () => {
  const { t } = useTranslation();

  const [isCookiesConsentVisible, setCookiesConsentVisible] =
    useState<boolean>(false);

  const onConsentAccept = () => {
    setCookiesConsentVisible(false);

    new Cookies().set('sm_user_cookies_granted', true);
  };

  useEffect(() => {
    const isCookiesGranted = new Cookies().get('sm_user_cookies_granted');

    if (!isCookiesGranted) {
      setCookiesConsentVisible(true);
    }
  }, []);

  if (!isCookiesConsentVisible) {
    return null;
  }

  return (
    <div className="cookies-consent">
      <p className="cookies-consent__text">
        {t('cookiesConsent.text')}
        <Link href={getFullUrl('/privacy-policy')} legacyBehavior>
          <a className="cookies-consent__link">{t('cookiesConsent.link')}</a>
        </Link>
      </p>
      <button
        className="cookies-consent__btn btn btn-type-blue"
        onClick={onConsentAccept}
      >
        {t('cookiesConsent.btn')}
      </button>
    </div>
  );
};

export default CookiesConsent;
