import FooterDiscord from './FooterDiscord';
import Footnotes from './Footnotes';

const Footer = () => {
  return (
    <footer className="footer">
      <FooterDiscord />
      <Footnotes />
    </footer>
  );
};

export default Footer;
