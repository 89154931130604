import classNames from 'classnames';

const BackgroundArrowSecond = (props: React.SVGProps<SVGSVGElement>) => {
  const { className = '', ...attributes } = props;
  const classes = classNames('background-arrow-second', className);

  return (
    <svg
      width="46.81"
      height="55.074"
      viewBox="0 0 46.81 55.074"
      className={classes}
      {...attributes}
    >
      <defs>
        <clipPath id="background-arrow-second-clip-path">
          <rect
            id="Rectangle_1245"
            data-name="Rectangle 1245"
            width="46.81"
            height="55.074"
            fill="#fff"
          />
        </clipPath>
      </defs>
      <g
        id="Group_11450"
        data-name="Group 11450"
        clipPath="url(#background-arrow-second-clip-path)"
      >
        <path
          id="Path_30200"
          data-name="Path 30200"
          d="M53.164,31.35A2.747,2.747,0,1,0,50.418,34.1a2.749,2.749,0,0,0,2.746-2.747"
          transform="translate(-6.354 -3.813)"
          fill="#fff"
        />
        <path
          id="Path_30201"
          data-name="Path 30201"
          d="M40.884,34.1a2.747,2.747,0,1,0-2.747-2.747A2.749,2.749,0,0,0,40.884,34.1"
          transform="translate(-5.083 -3.813)"
          fill="#fff"
        />
        <path
          id="Path_30202"
          data-name="Path 30202"
          d="M40.884,24.561a2.747,2.747,0,1,0-2.747-2.747,2.749,2.749,0,0,0,2.747,2.747"
          transform="translate(-5.083 -2.542)"
          fill="#fff"
        />
        <path
          id="Path_30203"
          data-name="Path 30203"
          d="M31.35,15.027A2.747,2.747,0,1,0,28.6,12.281a2.75,2.75,0,0,0,2.747,2.747"
          transform="translate(-3.812 -1.271)"
          fill="#fff"
        />
        <path
          id="Path_30204"
          data-name="Path 30204"
          d="M40.884,43.63a2.747,2.747,0,1,0-2.747-2.747,2.749,2.749,0,0,0,2.747,2.747"
          transform="translate(-5.083 -5.083)"
          fill="#fff"
        />
        <path
          id="Path_30205"
          data-name="Path 30205"
          d="M31.35,34.1A2.747,2.747,0,1,0,28.6,31.35,2.75,2.75,0,0,0,31.35,34.1"
          transform="translate(-3.812 -3.813)"
          fill="#fff"
        />
        <path
          id="Path_30206"
          data-name="Path 30206"
          d="M31.35,24.561A2.747,2.747,0,1,0,28.6,21.815a2.75,2.75,0,0,0,2.747,2.747"
          transform="translate(-3.812 -2.542)"
          fill="#fff"
        />
        <path
          id="Path_30207"
          data-name="Path 30207"
          d="M31.35,43.63A2.747,2.747,0,1,0,28.6,40.884,2.75,2.75,0,0,0,31.35,43.63"
          transform="translate(-3.812 -5.083)"
          fill="#fff"
        />
        <path
          id="Path_30208"
          data-name="Path 30208"
          d="M31.35,53.164A2.747,2.747,0,1,0,28.6,50.418a2.75,2.75,0,0,0,2.747,2.747"
          transform="translate(-3.812 -6.354)"
          fill="#fff"
        />
        <path
          id="Path_30209"
          data-name="Path 30209"
          d="M21.816,15.027a2.747,2.747,0,1,0-2.747-2.747,2.75,2.75,0,0,0,2.747,2.747"
          transform="translate(-2.542 -1.271)"
          fill="#fff"
        />
        <path
          id="Path_30210"
          data-name="Path 30210"
          d="M21.816,24.561a2.747,2.747,0,1,0-2.747-2.747,2.75,2.75,0,0,0,2.747,2.747"
          transform="translate(-2.542 -2.542)"
          fill="#fff"
        />
        <path
          id="Path_30211"
          data-name="Path 30211"
          d="M21.816,43.63a2.747,2.747,0,1,0-2.747-2.747,2.75,2.75,0,0,0,2.747,2.747"
          transform="translate(-2.542 -5.083)"
          fill="#fff"
        />
        <path
          id="Path_30212"
          data-name="Path 30212"
          d="M21.816,53.164a2.747,2.747,0,1,0-2.747-2.747,2.75,2.75,0,0,0,2.747,2.747"
          transform="translate(-2.542 -6.354)"
          fill="#fff"
        />
        <path
          id="Path_30213"
          data-name="Path 30213"
          d="M12.281,15.027a2.747,2.747,0,1,0-2.747-2.747,2.749,2.749,0,0,0,2.747,2.747"
          transform="translate(-1.271 -1.271)"
          fill="#fff"
        />
        <path
          id="Path_30214"
          data-name="Path 30214"
          d="M21.816,5.493a2.747,2.747,0,1,0-2.747-2.747,2.75,2.75,0,0,0,2.747,2.747"
          transform="translate(-2.542 0)"
          fill="#fff"
        />
        <path
          id="Path_30215"
          data-name="Path 30215"
          d="M12.281,5.493A2.747,2.747,0,1,0,9.534,2.747a2.749,2.749,0,0,0,2.747,2.747"
          transform="translate(-1.271 0)"
          fill="#fff"
        />
        <path
          id="Path_30216"
          data-name="Path 30216"
          d="M2.747,5.493A2.747,2.747,0,1,0,0,2.747,2.749,2.749,0,0,0,2.747,5.493"
          transform="translate(0 0)"
          fill="#fff"
        />
        <path
          id="Path_30217"
          data-name="Path 30217"
          d="M12.281,53.164a2.747,2.747,0,1,0-2.747-2.747,2.749,2.749,0,0,0,2.747,2.747"
          transform="translate(-1.271 -6.354)"
          fill="#fff"
        />
        <path
          id="Path_30218"
          data-name="Path 30218"
          d="M21.816,62.7a2.747,2.747,0,1,0-2.747-2.747A2.75,2.75,0,0,0,21.816,62.7"
          transform="translate(-2.542 -7.625)"
          fill="#fff"
        />
        <path
          id="Path_30219"
          data-name="Path 30219"
          d="M12.281,62.7a2.747,2.747,0,1,0-2.747-2.747A2.749,2.749,0,0,0,12.281,62.7"
          transform="translate(-1.271 -7.625)"
          fill="#fff"
        />
        <path
          id="Path_30220"
          data-name="Path 30220"
          d="M2.747,62.7A2.747,2.747,0,1,0,0,59.952,2.749,2.749,0,0,0,2.747,62.7"
          transform="translate(0 -7.625)"
          fill="#fff"
        />
      </g>
    </svg>
  );
};

export default BackgroundArrowSecond;
