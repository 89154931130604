import classNames from 'classnames';

const FlagThaiIcon = (props: React.SVGProps<SVGSVGElement>) => {
  const { className = '', ...attributes } = props;
  const classes = classNames('flag-thai', className);

  return (
    <svg
      width="900"
      height="600"
      viewBox="0 0 900 600"
      className={classes}
      {...attributes}
    >
      <rect fill="#A51931" width="900" height="600" />
      <rect fill="#F4F5F8" y="100" width="900" height="400" />
      <rect fill="#2D2A4A" y="200" width="900" height="200" />
    </svg>
  );
};

export default FlagThaiIcon;
