import classNames from 'classnames';

const EnvelopeIcon = (props: React.SVGProps<SVGSVGElement>) => {
  const { className = '', ...attributes } = props;
  const classes = classNames('envelope-icon', className);

  return (
    <svg
      width="107"
      height="60"
      viewBox="0 0 107 60"
      className={classes}
      {...attributes}
    >
      <defs>
        <clipPath id="envelope-icon-clip-path">
          <rect
            id="Rectangle_1329"
            data-name="Rectangle 1329"
            width="107"
            height="60"
            transform="translate(0 0.188)"
            fill="rgba(0,0,0,0)"
            stroke="#c6cbdb"
            strokeWidth="2.5"
          />
        </clipPath>
      </defs>
      <g
        id="Group_11905"
        data-name="Group 11905"
        transform="translate(0 -0.188)"
        opacity="0.6"
      >
        <g
          id="Group_11904"
          data-name="Group 11904"
          clipPath="url(#envelope-icon-clip-path)"
        >
          <path
            id="Path_30918"
            data-name="Path 30918"
            d="M11,18.833V5.458A4.457,4.457,0,0,1,15.458,1H86.792A4.457,4.457,0,0,1,91.25,5.458V52.271a4.457,4.457,0,0,1-4.458,4.458H15.458A4.457,4.457,0,0,1,11,52.271V43.354"
            transform="translate(13.521 1.229)"
            fill="rgba(0,0,0,0)"
            stroke="#c6cbdb"
            strokeWidth="2.5"
          />
          <path
            id="Path_30919"
            data-name="Path 30919"
            d="M80.875,4,56.9,27.979a13.378,13.378,0,0,1-18.917,0L14,4"
            transform="translate(17.208 4.917)"
            fill="rgba(0,0,0,0)"
            stroke="#c6cbdb"
            strokeWidth="2.5"
          />
          <line
            id="Line_162"
            data-name="Line 162"
            y1="18"
            x2="18"
            transform="translate(31 33.188)"
            fill="none"
            stroke="#c6cbdb"
            strokeWidth="2.5"
          />
          <line
            id="Line_163"
            data-name="Line 163"
            x1="18"
            y1="18"
            transform="translate(80 33.188)"
            fill="none"
            stroke="#c6cbdb"
            strokeWidth="2.5"
          />
          <line
            id="Line_164"
            data-name="Line 164"
            x2="22"
            transform="translate(9 20.188)"
            fill="none"
            stroke="#c6cbdb"
            strokeWidth="2.5"
          />
          <line
            id="Line_165"
            data-name="Line 165"
            x2="24"
            transform="translate(0 29.188)"
            fill="none"
            stroke="#c6cbdb"
            strokeWidth="2.5"
          />
          <line
            id="Line_166"
            data-name="Line 166"
            x2="22"
            transform="translate(11 37.188)"
            fill="none"
            stroke="#c6cbdb"
            strokeWidth="2.5"
          />
        </g>
      </g>
    </svg>
  );
};

export default EnvelopeIcon;
