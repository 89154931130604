import { ReactNode } from 'react';
import classNames from 'classnames';
import dynamic from 'next/dynamic';

interface Props {
  className?: string;
  icon?: string;
  iconClassName?: string;
  header: string;
  description: string;
  children?: ReactNode;
}

const getIconComponent = (iconPath): any => {
  if (!iconPath) {
    return () => null;
  }

  return dynamic(() => import(`images/svg/${iconPath}`));
};

const Card = (props: Props) => {
  const { className, icon, iconClassName, header, description, children } =
    props;

  const cardClasses = classNames('card', className);

  const IconComponent = getIconComponent(icon);

  return (
    <div className={cardClasses}>
      <IconComponent className={iconClassName} />
      <div className="card__text-content">
        <h3 className="card__header header">{header}</h3>
        <p className="card__description header__description">{description}</p>
      </div>
      <>{children}</>
    </div>
  );
};

export default Card;
