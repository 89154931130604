import classNames from 'classnames';

const HddIcon = (props: React.SVGProps<SVGSVGElement>) => {
  const { className = '', ...attributes } = props;
  const classes = classNames('ssd-icon', className);

  return (
    <svg
      width="100%"
      viewBox="0 0 180 180"
      fill="none"
      className={classes}
      {...attributes}
    >
      <circle
        cx="90"
        cy="90"
        r="89.1667"
        fill="url(#paint0_linear_2145_232)"
        stroke="url(#paint1_linear_2145_232)"
        strokeWidth="1.66667"
      />
      <path
        d="M119.593 54.2574H61.4043V126.743H119.593V54.2574Z"
        stroke="#00B3FF"
        strokeWidth="2.64"
        strokeMiterlimit="10"
      />
      <path
        d="M108.105 54.2574H72.8906V63.8887H108.105V54.2574Z"
        stroke="#00B3FF"
        strokeWidth="2.64"
        strokeMiterlimit="10"
      />
      <path
        d="M111.2 82.5223C111.2 86.0012 109.269 88.7539 106.206 88.8073C105.186 88.8251 102.551 88.8075 102.551 88.8075V76.2095C102.551 76.2095 104.896 76.2095 106.101 76.2095C109.461 76.2095 111.2 79.0435 111.2 82.5223Z"
        stroke="#00B3FF"
        strokeWidth="2.64"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M70.8477 87.4346C71.9166 88.4088 73.5935 89.0895 75.4967 88.9074C75.7554 88.8827 76.0469 88.8069 76.3504 88.6994C78.4186 87.9678 79.1775 85.3766 77.7542 83.7073C76.8795 82.6814 74.5631 82.094 73.9561 81.9116C72.8437 81.5773 72.0225 80.6346 71.8431 79.487C71.5586 77.6659 72.9586 76.0033 74.8017 76.0026C76.4418 76.0018 77.7811 76.2847 77.7811 76.2847"
        stroke="#00B3FF"
        strokeWidth="2.64"
        strokeMiterlimit="10"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M86.5488 87.4346C87.6177 88.4088 89.2946 89.0895 91.1979 88.9074C91.4566 88.8827 91.7479 88.8069 92.0516 88.6994C94.1198 87.9678 94.8787 85.3766 93.4553 83.7073C92.5807 82.6814 90.2642 82.094 89.6573 81.9116C88.5449 81.5773 87.7237 80.6346 87.5443 79.487C87.2597 77.6659 88.6597 76.0033 90.5029 76.0026C92.1429 76.0018 93.4823 76.2847 93.4823 76.2847"
        stroke="#00B3FF"
        strokeWidth="2.64"
        strokeMiterlimit="10"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M70.8477 114.954V119.544"
        stroke="#00B3FF"
        strokeWidth="2.64"
        strokeMiterlimit="10"
      />
      <path
        d="M110.146 114.954V119.544"
        stroke="#00B3FF"
        strokeWidth="2.64"
        strokeMiterlimit="10"
      />
      <path
        d="M75.1953 99.9559H105.795"
        stroke="#00B3FF"
        strokeWidth="2.64"
        strokeMiterlimit="10"
      />
      <path
        d="M84.375 54.2574V63.8887"
        stroke="#00B3FF"
        strokeWidth="2.64"
        strokeMiterlimit="10"
      />
      <path
        d="M96.6191 54.2574V63.8887"
        stroke="#00B3FF"
        strokeWidth="2.64"
        strokeMiterlimit="10"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2145_232"
          x1="36.6667"
          y1="-7.77778"
          x2="134.444"
          y2="180"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#05638C" />
          <stop offset="0.589072" stopColor="#041922" />
          <stop offset="1" stopColor="#090C16" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2145_232"
          x1="43"
          y1="15.5"
          x2="146"
          y2="170"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1BBAFF" />
          <stop offset="0.440508" stopColor="#12182D" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default HddIcon;
