import classNames from 'classnames';

const QuestionMarksIcon = (props: React.SVGProps<SVGSVGElement>) => {
  const { className = '', ...attributes } = props;
  const classes = classNames('question-marks-icon', className);

  return (
    <svg
      width="109.775"
      height="101.363"
      viewBox="0 0 109.775 101.363"
      className={classes}
      {...attributes}
    >
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="0.5"
          x2="0.5"
          y2="1"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#009fe3" />
          <stop offset="1" stopColor="#afca0b" />
        </linearGradient>
      </defs>
      <path
        id="Union_15"
        data-name="Union 15"
        d="M-2515.144,6664.2a4.236,4.236,0,0,1-1.358-3.143v-10.276h-4.9a6.606,6.606,0,0,1-6.6-6.6v-43.69a6.6,6.6,0,0,1,6.6-6.6h50.588a6.6,6.6,0,0,1,6.6,6.6v43.69a6.606,6.606,0,0,1-6.6,6.6H-2497.5l-11.467,13.106a4.227,4.227,0,0,1-3.217,1.474A4.341,4.341,0,0,1-2515.144,6664.2Zm-8.856-63.708v43.69a2.6,2.6,0,0,0,2.6,2.6h6.9a2,2,0,0,1,2,2v12.276a.249.249,0,0,0,.077.209.378.378,0,0,0,.242.1.252.252,0,0,0,.206-.107l12.066-13.791a2,2,0,0,1,1.505-.682h27.593a2.6,2.6,0,0,0,2.6-2.6v-43.69a2.6,2.6,0,0,0-2.6-2.6H-2521.4A2.6,2.6,0,0,0-2524,6600.493Zm89.044,45-11.47-13.108h-12.889v-4h13.8a2,2,0,0,1,1.5.683l12.069,13.791a.265.265,0,0,0,.33.083.266.266,0,0,0,.194-.279v-12.277a2,2,0,0,1,2-2h4.6a2.6,2.6,0,0,0,2.6-2.6V6584.4a2.6,2.6,0,0,0-2.6-2.6h-50.588a2.6,2.6,0,0,0-2.6,2.6v4.6h-4v-4.6a6.607,6.607,0,0,1,6.6-6.6h50.588a6.606,6.606,0,0,1,6.6,6.6v41.392a6.6,6.6,0,0,1-6.6,6.6h-2.6v10.278a4.266,4.266,0,0,1-2.787,4.024,4.361,4.361,0,0,1-1.527.279A4.268,4.268,0,0,1-2434.956,6645.493Zm-63.151-5.91v-4.6h4v4.6Zm0-9.2a12.111,12.111,0,0,1,.876-5.125,10.786,10.786,0,0,1,3.371-4.053c1.285-1.029,2.651-3,2.651-4.619a4.686,4.686,0,0,0-4.9-4.9,4.59,4.59,0,0,0-4.9,4.9h-4a8.931,8.931,0,0,1,2.5-6.4,8.928,8.928,0,0,1,6.4-2.5,8.891,8.891,0,0,1,6.363,2.532,8.9,8.9,0,0,1,2.534,6.365,8.92,8.92,0,0,1-1.343,4.476,12.221,12.221,0,0,1-2.81,3.266c-2.207,1.763-2.745,2.951-2.745,6.055Zm48.288-9.2v-4.6h4v4.6Zm0-9.2a12.082,12.082,0,0,1,.878-5.125,10.76,10.76,0,0,1,3.371-4.053c1.283-1.029,2.65-3,2.65-4.618a4.686,4.686,0,0,0-4.9-4.9,4.589,4.589,0,0,0-4.9,4.9h-4a8.938,8.938,0,0,1,2.5-6.4,8.931,8.931,0,0,1,6.4-2.5,8.9,8.9,0,0,1,6.365,2.533,8.9,8.9,0,0,1,2.534,6.367,8.942,8.942,0,0,1-1.343,4.476,12.272,12.272,0,0,1-2.81,3.265c-2.207,1.762-2.745,2.951-2.745,6.056Zm-64.385-23v-18.4a6.607,6.607,0,0,1,6.6-6.6h48.29a6.607,6.607,0,0,1,6.6,6.6v2.3h-4v-2.3a2.6,2.6,0,0,0-2.6-2.6h-48.29a2.6,2.6,0,0,0-2.6,2.6v18.4Z"
        transform="translate(2527.999 -6564)"
        fill="url(#linear-gradient)"
      />
    </svg>
  );
};

export default QuestionMarksIcon;
