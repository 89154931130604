import classNames from 'classnames';

interface Props {
  onClick: Function;
  isOpen: boolean;
}

const NavigationBarToggle = (props: Props) => {
  const { onClick, isOpen } = props;

  const buttonClasses = classNames('navigation-bar-toggle', {
    'navigation-bar-toggle--open': isOpen,
  });

  const onToggle = e => {
    e.preventDefault();
    e.stopPropagation();

    onClick();
  };

  return (
    <button
      className={buttonClasses}
      onClick={onToggle}
      aria-label="navigation toggle"
    >
      <span></span>
      <span></span>
      <span></span>
    </button>
  );
};

export default NavigationBarToggle;
