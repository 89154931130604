import { useContext, useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import { Context } from 'context/GlobalContext';
import classNames from 'classnames';
import {
  FlagChina,
  FlagRussia,
  FlagUsa,
  FlagSpain,
  FlagThai,
} from 'images/svg';
import Link from 'next/link';
import Cookies from 'universal-cookie';
import { dayInSeconds } from 'utils/timeConst';
import { getWithDomain } from 'services/EnvProvider';

interface Props {
  isOpen: boolean;
}

const LangSelectDropdown = (props: Props) => {
  const { isOpen } = props;

  const router = useRouter();

  const { setIsCurtainActive } = useContext(Context);

  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

  const dropdownMenuClass = classNames('language-dropdown__menu', {
    'language-dropdown__menu--open': isDropdownOpen,
  });

  const toggleArrowClasses = classNames('language-dropdown__toggle-arrow', {
    'language-dropdown__toggle-arrow--open': isDropdownOpen,
  });

  const onToggle = () => {
    setIsDropdownOpen(prev => !prev);
    setIsCurtainActive(true);
  };

  const onMenuItemClick = locale => {
    if (router.locale === locale) {
      setIsDropdownOpen(false);
      setIsCurtainActive(false);
    }

    localStorage.setItem('SM_USER_LOCALE', locale);

    new Cookies().set('sm_user_locale', locale, {
      path: '/',
      secure: process.env.NEXT_PUBLIC_ENV !== 'local',
      domain:
        process.env.NEXT_PUBLIC_ENV === 'local'
          ? 'localhost'
          : getWithDomain('.simplemining.net'),
      maxAge: 30 * dayInSeconds,
    });
  };

  const onCurtainClick = () => {
    setIsDropdownOpen(false);
    !isOpen && setIsCurtainActive(false);
  };

  useEffect(() => {
    if (!isOpen) {
      setIsDropdownOpen(false);
      setIsCurtainActive(false);
    }
  }, [isOpen, setIsCurtainActive]);

  return (
    <div className="language-dropdown">
      <button
        className="language-dropdown__toggle"
        type="button"
        aria-expanded={isOpen}
        aria-haspopup="true"
        aria-label="Dropdown toggle"
        onClick={onToggle}
      >
        {router.locale === 'en' && <FlagUsa />}
        {router.locale === 'ru' && <FlagRussia />}
        {router.locale === 'zh' && <FlagChina />}
        {router.locale === 'es' && <FlagSpain />}
        {router.locale === 'th' && <FlagThai />}
        <span className={toggleArrowClasses}>
          <span></span>
        </span>
      </button>
      <ul className={dropdownMenuClass} role="menu" aria-hidden={!isOpen}>
        <li>
          <Link href={router.asPath} locale="en" legacyBehavior>
            <a
              className="language-dropdown__menu-item-link"
              role="menuitem"
              onClick={() => onMenuItemClick('en')}
            >
              <FlagUsa />
              <span>English</span>
            </a>
          </Link>
        </li>
        {/* <li>
          <Link href={router.asPath} locale="ru" legacyBehavior>
            <a
              className="language-dropdown__menu-item-link"
              role="menuitem"
              onClick={() => onMenuItemClick('ru')}
            >
              <FlagRussia />
              <span>Pyccкий</span>
            </a>
          </Link>
        </li> */}
        <li>
          <Link href={router.asPath} locale="zh" legacyBehavior>
            <a
              className="language-dropdown__menu-item-link"
              role="menuitem"
              onClick={() => onMenuItemClick('zh')}
            >
              <FlagChina />
              <span>中文</span>
            </a>
          </Link>
        </li>
        <li>
          <Link href={router.asPath} locale="es" legacyBehavior>
            <a
              className="language-dropdown__menu-item-link"
              role="menuitem"
              onClick={() => onMenuItemClick('es')}
            >
              <FlagSpain />
              <span>Español</span>
            </a>
          </Link>
        </li>
        <li>
          <Link href={router.asPath} locale="th" legacyBehavior>
            <a
              className="language-dropdown__menu-item-link"
              role="menuitem"
              onClick={() => onMenuItemClick('th')}
            >
              <FlagThai />
              <span>แบบไทย</span>
            </a>
          </Link>
        </li>
      </ul>
      {isDropdownOpen && (
        <div
          className="language-dropdown__curtain"
          onClick={onCurtainClick}
        ></div>
      )}
    </div>
  );
};

export default LangSelectDropdown;
