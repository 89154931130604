import classNames from 'classnames';

const SpLogo = (props: React.SVGProps<SVGSVGElement>) => {
  const { className = '', ...attributes } = props;
  const classes = classNames('sp-logo', className);

  return (
    <svg
      viewBox="0 0 131 146"
      width={77.521}
      height={44}
      className={classes}
      {...attributes}
    >
      <defs>
        <style>
          {`
              .cls-1 {
                fill: url(#Gradient_bez_nazwy_12-9);
              }

              .cls-2 {
                fill: url(#Gradient_bez_nazwy_12-6);
              }

              .cls-3 {
                fill: url(#Gradient_bez_nazwy_12-7);
              }

              .cls-4 {
                fill: url(#Gradient_bez_nazwy_12-4);
              }

              .cls-5 {
                fill: url(#Gradient_bez_nazwy_12-10);
              }

              .cls-6 {
                fill: url(#Gradient_bez_nazwy_12-8);
              }

              .cls-7 {
                fill: url(#Gradient_bez_nazwy_12-5);
              }

              .cls-8 {
                fill: url(#Gradient_bez_nazwy_12-11);
              }

              .cls-9 {
                fill: url(#Gradient_bez_nazwy_12-2);
              }

              .cls-10 {
                fill: url(#Gradient_bez_nazwy_12-3);
              }

              .cls-11 {
                fill: url(#Gradient_bez_nazwy_12-12);
              }

              .cls-12 {
                fill: url(#Gradient_bez_nazwy_12);
              }
            
          `}
        </style>
        <linearGradient
          id="Gradient_bez_nazwy_12"
          data-name="Gradient bez nazwy 12"
          x1="-5.79"
          y1="-5196.63"
          x2="78.83"
          y2="-5111.85"
          gradientTransform="translate(0 -5101.7) scale(1 -1)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#009fe3" />
          <stop offset="1" stopColor="#afca0b" />
        </linearGradient>
        <linearGradient
          id="Gradient_bez_nazwy_12-2"
          data-name="Gradient bez nazwy 12"
          x1="-6.54"
          y1="-5195.88"
          x2="78.09"
          y2="-5111.1"
          xlinkHref="#Gradient_bez_nazwy_12"
        />
        <linearGradient
          id="Gradient_bez_nazwy_12-3"
          data-name="Gradient bez nazwy 12"
          x1="50.52"
          y1="-5252.83"
          x2="135.14"
          y2="-5168.05"
          xlinkHref="#Gradient_bez_nazwy_12"
        />
        <linearGradient
          id="Gradient_bez_nazwy_12-4"
          data-name="Gradient bez nazwy 12"
          x1="42.04"
          y1="-5244.37"
          x2="126.66"
          y2="-5159.59"
          xlinkHref="#Gradient_bez_nazwy_12"
        />
        <linearGradient
          id="Gradient_bez_nazwy_12-5"
          data-name="Gradient bez nazwy 12"
          x1="31.35"
          y1="-5233.7"
          x2="115.98"
          y2="-5148.92"
          xlinkHref="#Gradient_bez_nazwy_12"
        />
        <linearGradient
          id="Gradient_bez_nazwy_12-6"
          data-name="Gradient bez nazwy 12"
          x1="-6.15"
          y1="-5196.27"
          x2="78.47"
          y2="-5111.49"
          xlinkHref="#Gradient_bez_nazwy_12"
        />
        <linearGradient
          id="Gradient_bez_nazwy_12-7"
          data-name="Gradient bez nazwy 12"
          x1="-4.18"
          y1="-5198.24"
          x2="80.45"
          y2="-5113.46"
          xlinkHref="#Gradient_bez_nazwy_12"
        />
        <linearGradient
          id="Gradient_bez_nazwy_12-8"
          data-name="Gradient bez nazwy 12"
          x1="-8.6"
          y1="-5193.83"
          x2="76.03"
          y2="-5109.05"
          xlinkHref="#Gradient_bez_nazwy_12"
        />
        <linearGradient
          id="Gradient_bez_nazwy_12-9"
          data-name="Gradient bez nazwy 12"
          x1="27.2"
          y1="-5229.56"
          x2="111.83"
          y2="-5144.78"
          xlinkHref="#Gradient_bez_nazwy_12"
        />
        <linearGradient
          id="Gradient_bez_nazwy_12-10"
          data-name="Gradient bez nazwy 12"
          x1="-2.51"
          y1="-5199.9"
          x2="82.12"
          y2="-5115.13"
          xlinkHref="#Gradient_bez_nazwy_12"
        />
        <linearGradient
          id="Gradient_bez_nazwy_12-11"
          data-name="Gradient bez nazwy 12"
          x1=".82"
          y1="-5203.23"
          x2="85.45"
          y2="-5118.45"
          xlinkHref="#Gradient_bez_nazwy_12"
        />
        <linearGradient
          id="Gradient_bez_nazwy_12-12"
          data-name="Gradient bez nazwy 12"
          x1="88.97"
          y1="-5237.82"
          x2="122.52"
          y2="-5199.21"
          xlinkHref="#Gradient_bez_nazwy_12"
        />
      </defs>
      <path
        className="cls-12"
        d="M105.53,27.62c-.48.36-.86.85-1.08,1.41h-2.57s-12.26-12.28-12.26-12.28c-.08-.07-.17-.1-.28-.1H25.42c-.29-.72-.82-1.32-1.5-1.7-.68-.37-1.48-.5-2.24-.35-.76.15-1.45.56-1.95,1.16-.5.6-.77,1.35-.77,2.13s.27,1.53.77,2.13c.5.6,1.19,1.01,1.95,1.16.76.15,1.56.02,2.24-.35.68-.37,1.22-.97,1.5-1.7h63.01l12.26,12.26c.08.07.17.11.28.12h3.46c.17.41.43.79.74,1.1.31.31.68.56,1.08.73.41.17.84.26,1.28.25.6,0,1.2-.16,1.72-.48.52-.31.94-.76,1.23-1.29.28-.53.42-1.14.38-1.74-.03-.6-.23-1.19-.57-1.69-.34-.5-.81-.9-1.36-1.16-.55-.26-1.16-.36-1.76-.29-.6.06-1.17.29-1.66.65Z"
      />
      <path
        className="cls-9"
        d="M76.62,10.86h-21.56c-.18-.42-.44-.8-.76-1.12-.47-.46-1.06-.78-1.71-.9-.65-.13-1.31-.06-1.92.19-.61.25-1.13.68-1.49,1.23-.37.55-.56,1.19-.56,1.85s.19,1.3.56,1.85c.37.55.88.97,1.49,1.23.61.25,1.28.32,1.92.19.65-.13,1.24-.44,1.71-.9.31-.33.57-.71.74-1.12h22.45c.05,0,.1,0,.15-.03.05-.02.09-.05.12-.09l8.64-8.64h18.01c.29.72.82,1.32,1.5,1.7.68.37,1.48.5,2.24.35.76-.15,1.46-.56,1.95-1.16.5-.6.77-1.35.77-2.13s-.27-1.53-.77-2.13c-.49-.6-1.18-1.01-1.95-1.16-.76-.15-1.56-.02-2.24.35-.68.37-1.22.97-1.5,1.7h-18.89c-.1,0-.2.05-.28.12l-8.64,8.64Z"
      />
      <path
        className="cls-10"
        d="M79.5,141.43h-35.97c-.18-.42-.43-.8-.74-1.12-.64-.61-1.48-.96-2.37-.97-.55,0-1.09.13-1.58.39s-.9.63-1.21,1.09c-.31.46-.49.98-.55,1.53-.05.55.03,1.1.24,1.61.21.51.54.96.97,1.31.43.35.93.59,1.47.69.54.11,1.1.08,1.62-.08.53-.16,1-.45,1.39-.84.31-.32.57-.71.74-1.12h37.46l-1.48-2.49Z"
      />
      <path
        className="cls-4"
        d="M74,131.88h-38.09l-4.8,6.65c.26.48.39,1.01.38,1.55,0,.66-.2,1.3-.57,1.85-.37.55-.89.97-1.5,1.22-.61.25-1.28.31-1.92.18-.65-.13-1.24-.45-1.7-.92-.46-.47-.78-1.06-.91-1.71-.13-.65-.06-1.32.19-1.92s.68-1.13,1.23-1.49c.55-.37,1.19-.56,1.85-.56.36,0,.73.07,1.07.19l5.37-7.38c.03-.05.08-.1.13-.12.05-.03.11-.05.18-.05h37.59l1.5,2.51Z"
      />
      <path
        className="cls-7"
        d="M105,83.51h-4.66l-9.36-6.68h-20.73c-.16.42-.4.81-.73,1.12-.47.47-1.06.79-1.72.93-.65.13-1.33.07-1.94-.19-.61-.25-1.14-.68-1.51-1.23-.37-.55-.57-1.2-.57-1.86s.2-1.31.57-1.86c.37-.55.89-.98,1.51-1.23.61-.25,1.29-.32,1.94-.19.65.13,1.25.45,1.72.93.34.32.62.7.81,1.12h0s21.35,0,21.35,0c.07-.03.15-.03.22,0l13.09,9.15Z"
      />
      <path
        className="cls-2"
        d="M51.36,63.29c-.61.37-1.08.93-1.35,1.6l-15.17-.06-15.54-15.56v-6.77c.42-.16.81-.42,1.12-.74.47-.47.79-1.06.92-1.71.13-.65.06-1.32-.19-1.93s-.68-1.13-1.23-1.5c-.55-.37-1.2-.56-1.86-.56s-1.31.2-1.86.56c-.55.37-.98.89-1.23,1.5-.25.61-.32,1.28-.19,1.93.13.65.45,1.24.92,1.71.32.33.7.58,1.12.74v7.64c0,.1.03.2.1.28l16.85,16.84s.08.07.12.09c.05.02.1.03.15.03h15.96c.16.42.42.81.74,1.12.51.5,1.16.83,1.87.93.71.1,1.43-.03,2.06-.37.63-.34,1.13-.88,1.44-1.52.3-.65.39-1.38.24-2.08-.14-.7-.51-1.34-1.04-1.81-.53-.48-1.21-.77-1.92-.83-.71-.06-1.43.1-2.04.48Z"
      />
      <path
        className="cls-3"
        d="M75.16,83.22c-.48.36-.86.85-1.08,1.41h-14.01s-12.25-12.26-12.25-12.26c-.08-.07-.17-.1-.28-.1h-15.87L4.59,45.14v-23.9c.41-.17.79-.41,1.1-.73.46-.47.78-1.06.9-1.71.13-.65.06-1.31-.19-1.92s-.68-1.13-1.23-1.49c-.55-.36-1.19-.56-1.85-.56s-1.3.19-1.85.56c-.55.37-.97.88-1.23,1.49-.25.61-.32,1.28-.19,1.92.13.65.44,1.24.9,1.71.32.32.7.57,1.12.74v24.77s0,.1.03.14c.02.05.05.08.09.11l28.33,28.33c.08.07.17.11.28.1h15.86l12.26,12.28c.08.07.17.11.28.1h14.87c.16.42.42.81.74,1.12.31.31.68.56,1.08.73.41.17.84.25,1.28.25.6,0,1.2-.17,1.71-.48.52-.31.94-.76,1.22-1.3.28-.53.41-1.14.38-1.74-.03-.6-.23-1.19-.57-1.69-.34-.5-.81-.9-1.36-1.15-.55-.25-1.16-.35-1.76-.29-.6.06-1.17.29-1.65.65Z"
      />
      <path
        className="cls-6"
        d="M30.81,45.34v-3.07c.43-.18.81-.46,1.12-.81.62-.6.98-1.43,1-2.3-.06-.85-.44-1.64-1.06-2.22-.62-.58-1.44-.9-2.29-.9s-1.67.32-2.29.9c-.62.58-1,1.37-1.06,2.22,0,.44.08.87.25,1.28.17.41.41.78.72,1.09.32.32.7.57,1.12.74v3.96s0,.1.02.14c.02.04.04.08.08.12l8.64,8.64v1.36c-.72.29-1.33.82-1.7,1.5-.38.68-.5,1.48-.35,2.24.15.77.56,1.46,1.16,1.95.6.5,1.36.77,2.14.77s1.53-.27,2.14-.77,1.01-1.19,1.16-1.95c.15-.77.02-1.56-.35-2.24-.38-.68-.98-1.21-1.7-1.5v-2.25s0-.1-.02-.14c-.02-.04-.04-.09-.08-.12l-8.64-8.64Z"
      />
      <path
        className="cls-1"
        d="M11.16,130.55h17.04s.1,0,.14-.02c.04-.02.08-.04.12-.08l9.08-8.28h20.97c.27.67.76,1.23,1.37,1.6.62.37,1.34.54,2.06.47.72-.07,1.39-.37,1.93-.85.53-.48.9-1.13,1.04-1.83.14-.71.05-1.44-.26-2.09s-.82-1.19-1.46-1.53c-.64-.34-1.37-.46-2.08-.36-.71.11-1.37.44-1.88.95-.32.32-.57.69-.74,1.1h-21.76s-.1,0-.14.03c-.04.02-.09.04-.12.08l-9.08,8.29h-7.51l-6.6-9.16c.32-.52.5-1.12.52-1.73,0-.66-.19-1.31-.56-1.87-.37-.55-.89-.99-1.5-1.24-.61-.26-1.29-.33-1.94-.2-.65.13-1.25.44-1.72.91-.47.47-.79,1.07-.92,1.72-.13.65-.07,1.32.19,1.94.25.61.68,1.14,1.23,1.51.55.37,1.2.57,1.86.57.27,0,.55-.03.81-.1l5.57,7.7h-6.74c-.06,0-.12.01-.17.04-.05.03-.1.07-.14.11l-5.37,7.36c-.35-.11-.71-.17-1.07-.17-.66,0-1.3.2-1.85.56-.55.37-.98.89-1.23,1.5-.25.61-.32,1.28-.19,1.93.13.65.45,1.24.91,1.71.47.47,1.06.78,1.71.91.65.13,1.32.06,1.93-.19.61-.25,1.13-.68,1.5-1.23.37-.55.56-1.19.56-1.85,0-.54-.13-1.06-.38-1.54l4.87-6.65Z"
      />
      <path
        className="cls-5"
        d="M105.95,37.54c-.68.38-1.21.98-1.5,1.7h-9.45l-12.68-15.44s-.08-.09-.13-.11c-.05-.03-.11-.04-.17-.03h-46.36c-.06,0-.11,0-.16.03-.05.02-.1.06-.13.11l-6.27,7.65h-15.55c-.29-.72-.82-1.33-1.5-1.7-.68-.38-1.48-.5-2.24-.35-.77.15-1.46.55-1.95,1.16-.5.6-.77,1.36-.77,2.14s.27,1.53.77,2.14c.5.6,1.19,1.01,1.95,1.16.77.15,1.56.02,2.24-.35.68-.38,1.21-.98,1.5-1.7h16.48c.06,0,.11,0,.17-.03.05-.03.1-.06.13-.11l6.29-7.65h44.37l12.66,15.44s.08.09.13.11c.05.03.11.04.17.03h10.5c.29.72.82,1.33,1.5,1.7.68.38,1.48.5,2.24.35.77-.15,1.46-.55,1.95-1.16.5-.6.77-1.36.77-2.14s-.27-1.53-.77-2.14c-.5-.6-1.19-1.01-1.95-1.16-.76-.15-1.56-.02-2.24.35Z"
      />
      <path
        className="cls-8"
        d="M79.89,33.68l6.25,8.08v.04c-.46.59-.71,1.33-.71,2.08s.27,1.48.74,2.07c.47.59,1.13.99,1.87,1.16.74.16,1.51.07,2.18-.26.68-.33,1.22-.88,1.55-1.56.32-.68.41-1.45.24-2.19-.17-.73-.58-1.39-1.17-1.86-.59-.47-1.32-.73-2.08-.73h-.45l-7.1-9.17s-.08-.09-.13-.12-.11-.04-.17-.04h-38.4c-.29-.72-.82-1.31-1.5-1.68-.68-.37-1.47-.49-2.23-.35-.76.15-1.45.55-1.94,1.15-.49.6-.76,1.35-.76,2.12s.27,1.52.76,2.12c.49.6,1.18,1,1.94,1.15.76.15,1.55.03,2.23-.35.68-.37,1.21-.97,1.5-1.68h37.36Z"
      />
      <path
        className="cls-11"
        d="M105.53,88l-.08.04-.08-.04-25.38,14.62v29.23l25.38,14.62,25.38-14.62v-29.03l.24-.14-25.47-14.67ZM104.07,142.67l-21.43-12.34v-26.17l21.57,12.42-.13,26.09ZM85.33,102.67l20.12-11.59,20.2,11.63-20.12,11.59-20.2-11.63ZM128.12,130.33l-21.41,12.33.13-26.07,21.28-12.25v26Z"
      />
    </svg>
  );
};

export default SpLogo;
