import { useState, useEffect } from 'react';

const useScroll = () => {
  const [scrollPosition, setScrollPosition] = useState({
    currentY: 0,
    lastY: 0,
  });

  useEffect(() => {
    const onScroll = () => {
      setScrollPosition(prev => ({
        currentY: window.scrollY,
        lastY: prev.currentY,
      }));
    };

    onScroll();
    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  return {
    isScrollActive:
      scrollPosition.currentY > 150 &&
      scrollPosition.currentY - scrollPosition.lastY > 0,
    isScrollOnStartPosition: scrollPosition.currentY === 0,
  };
};

export default useScroll;
