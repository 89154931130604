import { useEffect } from 'react';
import { NextPageContext } from 'next';
import { Trans, useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useRouter } from 'next/router';
import PageError from 'components/PageError';
import { homePageUrl } from 'utils/isSimpleMiningUrl';

const Custom404 = () => {
  const router = useRouter();

  const { t } = useTranslation();

  useEffect(() => {
    const timeout = setTimeout(() => {
      router.push(homePageUrl());
    }, 5000);

    return () => {
      clearTimeout(timeout);
    };
  }, [router]);

  const errorDescription = (
    <Trans i18nKey="custom404.description">
      The page you are looking for was not found. <br />
      You&apos;ll be redirected to Home page within 5s.
    </Trans>
  );

  return (
    <PageError
      header="404"
      subheader={t('custom404.header')}
      description={errorDescription}
    />
  );
};

export async function getStaticProps(context: NextPageContext) {
  const { locale = 'en' } = context;

  return {
    props: await serverSideTranslations(locale),
  };
}

export default Custom404;
