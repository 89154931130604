import classNames from 'classnames';

const AmdLogo = (props: React.SVGProps<SVGSVGElement>) => {
  const { className = '', ...attributes } = props;
  const classes = classNames('amd-logo', className);

  return (
    <svg
      id="logo_white"
      width="206.762"
      height="49.323"
      viewBox="0 0 206.762 49.323"
      className={classes}
      {...attributes}
    >
      <path
        id="Path_30183"
        data-name="Path 30183"
        d="M48.767,45H37.239l-3.507-8.465H14.613L11.416,45H.14L17.365,2.26H29.707ZM23.773,12.441,17.439,29.089H30.595Z"
        transform="translate(-0.14 1.04)"
        fill="#fff"
      />
      <path
        id="Path_30184"
        data-name="Path 30184"
        d="M73,2.26h9.279V45H71.628V18.361L60.1,31.768H58.473L46.945,18.3V44.938H36.29V2.26h9.279L59.287,18.153Z"
        transform="translate(17.206 1.04)"
        fill="#fff"
      />
      <path
        id="Path_30185"
        data-name="Path 30185"
        d="M91.728,2.26c15.6,0,23.677,9.708,23.677,21.428,0,12.283-7.769,21.31-24.817,21.31H72.86V2.26ZM83.515,37.169H90.47c10.714,0,13.91-7.266,13.91-13.54,0-7.4-3.951-13.54-14.029-13.54H83.515Z"
        transform="translate(34.753 1.04)"
        fill="#fff"
      />
      <path
        id="Path_30186"
        data-name="Path 30186"
        d="M142.137,13.437H120.1L106.68,0h48.894V48.908L142.137,35.472Z"
        transform="translate(51.188)"
        fill="#009a66"
      />
      <path
        id="Path_30187"
        data-name="Path 30187"
        d="M120.222,30.256V10.9L106.4,24.722V44.093h19.356l13.822-13.836Z"
        transform="translate(51.054 5.23)"
        fill="#009a66"
      />
    </svg>
  );
};

export default AmdLogo;
