import classNames from 'classnames';

const MinerIcon = (props: React.SVGProps<SVGSVGElement>) => {
  const { className = '', ...attributes } = props;
  const classes = classNames('miner-icon', className);

  return (
    <svg
      width="31"
      height="30"
      viewBox="0 0 31 30"
      fill="none"
      className={classes}
      {...attributes}
    >
      <g clipPath="url(#clip0_2145_489)">
        <path
          d="M8.46875 23.2033L15.7844 15.8877"
          stroke="#00B3FF"
          strokeWidth="2.34375"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.8125 13.8281C14.6957 13.8281 17.8438 16.9761 17.8438 20.8594"
          stroke="#00B3FF"
          strokeWidth="2.34375"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22.5312 18.5156H24.875"
          stroke="#00B3FF"
          strokeWidth="2.34375"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.4727 13.544L22.1299 11.8867"
          stroke="#00B3FF"
          strokeWidth="2.34375"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.9844 2.10938H25.8125C27.7541 2.10938 29.3281 3.68338 29.3281 5.625V6.79688H15.5V5.625C15.5 3.68338 13.926 2.10938 11.9844 2.10938ZM11.9844 2.10938H5.1875C3.24588 2.10938 1.67188 3.68338 1.67188 5.625V23.2031C1.67188 25.792 3.77053 27.8906 6.35938 27.8906H24.6406C27.2295 27.8906 29.3281 25.792 29.3281 23.2031V11.4844"
          stroke="#00B3FF"
          strokeWidth="2.34375"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.35938 6.79688H10.8125"
          stroke="#00B3FF"
          strokeWidth="2.34375"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2145_489">
          <rect
            width="30"
            height="30"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MinerIcon;
