import classNames from 'classnames';

const NvidiaLogo = (props: React.SVGProps<SVGSVGElement>) => {
  const { className = '', ...attributes } = props;
  const classes = classNames('nvidia-logo', className);

  return (
    <svg
      id="NVIDIA"
      width="259.493"
      height="49.323"
      viewBox="0 0 259.493 49.323"
      className={classes}
      {...attributes}
    >
      <path
        id="Path_30188"
        data-name="Path 30188"
        d="M230.351,17.107V49.422h8.99V17.107Zm-71.919,0V49.422h9.233V24.153h7.289c2.43,0,3.888.486,5.1,1.7,1.458,1.458,1.944,4.131,1.944,8.5V49.179h8.99V31.442c0-12.877-8.261-14.578-16.036-14.578Zm86.5,0V49.422H259.75c8.018,0,10.448-1.215,13.363-4.131,1.944-1.944,3.159-6.56,3.159-11.663,0-4.616-.972-8.5-2.916-11.177-3.4-4.616-8.261-5.345-15.55-5.345Zm8.99,7.046h3.888c5.588,0,9.476,2.43,9.476,9.233s-3.645,9.233-9.476,9.233h-3.888Zm-36.932-7.046-7.532,25.755-7.289-25.755H192.2l10.448,32.315h13.12L226.22,17.107Zm63.658,32.315h9.233V17.107h-9.233Zm25.512-32.315L293.523,49.422h8.99l1.944-5.831h15.064l1.944,5.588h9.719L318.306,16.864Zm5.831,5.831L317.577,38H306.4Z"
        transform="translate(-71.691 -7.631)"
        fill="#fff"
      />
      <path
        id="Path_30189"
        data-name="Path 30189"
        d="M27.7,14.821V10.448h1.215C41.062,9.962,49.08,20.9,49.08,20.9S40.576,32.8,31.343,32.8a18.755,18.755,0,0,1-3.645-.486V18.709c4.859.486,5.588,2.673,8.5,7.289l6.317-5.345S37.9,14.578,30.128,14.578c-.729,0-1.458.243-2.43.243M27.7,0V6.56h1.215C45.921,6.074,56.855,20.41,56.855,20.41S44.221,35.717,31.1,35.717a16.041,16.041,0,0,1-3.4-.243V39.6c.972,0,1.944.243,2.916.243,12.392,0,21.138-6.317,29.885-13.606,1.458,1.215,7.289,3.888,8.5,5.1-8.261,6.8-27.456,12.392-38.146,12.392a11.829,11.829,0,0,1-2.916-.243v5.831h46.65V0Zm0,32.072v3.4C16.279,33.53,13.12,21.624,13.12,21.624A24.067,24.067,0,0,1,27.7,14.578v3.888h0c-4.859-.486-8.5,3.888-8.5,3.888s2.187,7.532,8.5,9.719M7.532,21.381A28.146,28.146,0,0,1,27.7,10.448V6.8C12.877,8.018,0,20.653,0,20.653S7.289,41.791,27.7,43.735V39.847C12.634,37.9,7.532,21.381,7.532,21.381Z"
        transform="translate(0)"
        fill="#76b900"
      />
    </svg>
  );
};

export default NvidiaLogo;
