import classNames from 'classnames';

const PcIcon = (props: React.SVGProps<SVGSVGElement>) => {
  const { className = '', ...attributes } = props;
  const classes = classNames('pc-icon', className);

  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      className={classes}
      {...attributes}
    >
      <circle
        cx="32"
        cy="32"
        r="31.4286"
        fill="url(#paint0_linear_2145_681)"
        fillOpacity="0.5"
        stroke="#222332"
        strokeWidth="1.14286"
      />
      <g clipPath="url(#clip0_2145_681)">
        <path
          d="M33.873 36.2178H17.877V22.9756C17.877 22.0047 18.6639 21.2178 19.6348 21.2178H33.5801"
          stroke="#00B3FF"
          strokeWidth="1.84615"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M33.873 39.9678H19.6348C18.6639 39.9678 17.877 39.1809 17.877 38.21V36.2178H33.873"
          stroke="#00B3FF"
          strokeWidth="1.84615"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M30.5918 39.9678H25.9043V44.6553H30.5918V39.9678Z"
          stroke="#00B3FF"
          strokeWidth="1.84615"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22.623 44.6553H33.873"
          stroke="#00B3FF"
          strokeWidth="1.84615"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M46.1191 19.3428H33.873V44.6553H46.1191V19.3428Z"
          stroke="#00B3FF"
          strokeWidth="1.84615"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M42.7793 34.8115H37.1543V41.374H42.7793V34.8115Z"
          stroke="#00B3FF"
          strokeWidth="1.84615"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M39.9668 31.0615C41.0023 31.0615 41.8418 30.2221 41.8418 29.1865C41.8418 28.151 41.0023 27.3115 39.9668 27.3115C38.9313 27.3115 38.0918 28.151 38.0918 29.1865C38.0918 30.2221 38.9313 31.0615 39.9668 31.0615Z"
          stroke="#00B3FF"
          strokeWidth="1.84615"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M37.1543 22.624H42.7793"
          stroke="#00B3FF"
          strokeWidth="1.84615"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_2145_681"
          x1="32"
          y1="0"
          x2="32"
          y2="64"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#222332" />
          <stop offset="1" stopColor="#0C101C" />
        </linearGradient>
        <clipPath id="clip0_2145_681">
          <rect
            width="30"
            height="30"
            fill="white"
            transform="translate(17 17)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PcIcon;
