import classNames from 'classnames';

const ArrowCircleIcon = (props: React.SVGProps<SVGSVGElement>) => {
  const { className = '', ...attributes } = props;
  const classes = classNames('arrow-circle-icon', className);

  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      className={classes}
      {...attributes}
    >
      <path
        id="Path_30920"
        data-name="Path 30920"
        d="M0,10.123,5.062,5.062,0,0"
        transform="translate(12.746 9.992)"
        fill="none"
        stroke="#009fe3"
        strokeMiterlimit="10"
        strokeWidth="1.8"
        fillRule="evenodd"
      />
      <g
        id="Ellipse_140"
        data-name="Ellipse 140"
        fill="none"
        stroke="#009fe3"
        strokeWidth="1.8"
      >
        <circle cx="15" cy="15" r="15" stroke="none" />
        <circle cx="15" cy="15" r="14.1" fill="none" />
      </g>
    </svg>
  );
};

export default ArrowCircleIcon;
