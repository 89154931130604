import classNames from 'classnames';

const BoostIcon = (props: React.SVGProps<SVGSVGElement>) => {
  const { className = '', ...attributes } = props;
  const classes = classNames('boost-icon', className);

  return (
    <svg
      width="78"
      height="77"
      viewBox="0 0 78 77"
      fill="none"
      className={classes}
      {...attributes}
    >
      <mask
        id="mask0_2145_373"
        maskUnits="userSpaceOnUse"
        x="0"
        y="-1"
        width="78"
        height="78"
      >
        <path
          d="M0.496094 -0.000968933H77.4961V76.999H0.496094V-0.000968933Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_2145_373)">
        <path
          d="M12.4171 38.6711C12.4642 45.9375 15.4326 52.5162 20.206 57.2897L13.3727 64.1226C6.8125 57.5621 2.75195 48.501 2.75195 38.4992H12.4171V38.6711ZM38.9961 11.9202C31.6615 11.9202 25.0168 14.8976 20.206 19.7087L13.3727 12.8759C19.933 6.31511 28.9941 2.25501 38.9961 2.25501V11.9202Z"
          stroke="#00B3FF"
          strokeWidth="2.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.3727 12.8756L20.206 19.7085C15.3938 24.5195 12.4171 31.1643 12.4171 38.499H2.75195C2.75195 28.4973 6.8125 19.4363 13.3727 12.8756Z"
          stroke="#00B3FF"
          strokeWidth="2.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M64.6204 12.8756L57.7871 19.7085C62.5993 24.5195 65.576 31.1643 65.576 38.499H75.2411C75.2411 28.4973 71.1806 19.4363 64.6204 12.8756Z"
          stroke="#00B3FF"
          strokeWidth="2.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M42.4118 41.916C40.5259 43.8021 37.4644 43.8021 35.5784 41.916C33.6926 40.0301 33.6926 36.9677 35.5784 35.0818C38.9951 31.6645 57.789 19.7048 57.789 19.7048C57.789 19.7048 45.8296 38.4989 42.4118 41.916Z"
          stroke="#00B3FF"
          strokeWidth="2.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M60.7422 52.9968V60.2456H65.5748"
          stroke="#00B3FF"
          strokeWidth="2.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M64.4335 46.2228C65.1595 43.8304 65.5582 41.2956 65.5752 38.6709V38.499H75.2402C75.2402 43.26 74.3209 47.8076 72.6488 51.9751C70.7061 49.186 67.8102 47.1103 64.4335 46.2228ZM38.9961 11.9201C46.3308 11.9201 52.9755 14.8975 57.7863 19.7085L64.6195 12.8756C58.0593 6.315 48.9983 2.25491 38.9961 2.25491V11.9201Z"
          stroke="#00B3FF"
          strokeWidth="2.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M60.7416 45.7479C68.7432 45.7479 75.2393 52.244 75.2393 60.2455C75.2393 68.2469 68.7432 74.7432 60.7416 74.7432C52.7403 74.7432 46.2441 68.2469 46.2441 60.2455C46.2441 52.244 52.7403 45.7479 60.7416 45.7479Z"
          stroke="#00B3FF"
          strokeWidth="2.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export default BoostIcon;
