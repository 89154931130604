import classNames from 'classnames';

const DiscordLogo = (props: React.SVGProps<SVGSVGElement>) => {
  const { className = '', ...attributes } = props;
  const classes = classNames('discord-logo', className);

  return (
    <svg
      viewBox="0 0 100 78"
      fill="none"
      width="100"
      height="78"
      className={classes}
      {...attributes}
    >
      <path
        d="M84.7095 6.61586C78.2363 3.58722 71.3149 1.38613 64.0787 0.132812C63.19 1.73952 62.1518 3.90059 61.436 5.6197C53.7437 4.46289 46.1223 4.46289 38.5715 5.6197C37.8558 3.90059 36.794 1.73952 35.8973 0.132812C28.6534 1.38613 21.724 3.59531 15.2508 6.6319C2.19434 26.3619 -1.34505 45.6019 0.424653 64.5686C9.08438 71.0355 17.4767 74.9639 25.7274 77.5346C27.7645 74.7309 29.5814 71.7505 31.1466 68.6095C28.1656 67.4768 25.3105 66.079 22.6128 64.4562C23.3285 63.926 24.0286 63.3717 24.7049 62.8013C41.1591 70.4973 59.0371 70.4973 75.2947 62.8013C75.979 63.3717 76.679 63.926 77.3868 64.4562C74.6812 66.0869 71.8183 67.4847 68.8373 68.6176C70.4025 71.7505 72.2115 74.739 74.2565 77.5425C82.5151 74.9719 90.9152 71.0436 99.575 64.5686C101.651 42.5813 96.0278 23.5181 84.7095 6.61586ZM33.3882 52.9043C28.4488 52.9043 24.3981 48.293 24.3981 42.6777C24.3981 37.0624 28.3623 32.4432 33.3882 32.4432C38.4143 32.4432 42.4648 37.0543 42.3783 42.6777C42.3861 48.293 38.4143 52.9043 33.3882 52.9043ZM66.6114 52.9043C61.672 52.9043 57.6213 48.293 57.6213 42.6777C57.6213 37.0624 61.5854 32.4432 66.6114 32.4432C71.6373 32.4432 75.688 37.0543 75.6015 42.6777C75.6015 48.293 71.6373 52.9043 66.6114 52.9043Z"
        fill="#5865F2"
      />
    </svg>
  );
};

export default DiscordLogo;
