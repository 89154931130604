import classNames from 'classnames';

const ArrowRightRedirect = (props: React.SVGProps<SVGSVGElement>) => {
  const { className = '', ...attributes } = props;
  const classes = classNames('arrow-right-redirect-icon', className);

  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      className={classes}
      {...attributes}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.5 8C2.5 7.58579 2.83579 7.25 3.25 7.25L11.9393 7.25L8.71967 4.03033C8.42678 3.73744 8.42678 3.26256 8.71967 2.96967C9.01256 2.67678 9.48744 2.67678 9.78033 2.96967L14.2803 7.46967C14.5732 7.76256 14.5732 8.23744 14.2803 8.53033L9.78033 13.0303C9.48744 13.3232 9.01256 13.3232 8.71967 13.0303C8.42678 12.7374 8.42678 12.2626 8.71967 11.9697L11.9393 8.75L3.25 8.75C2.83579 8.75 2.5 8.41421 2.5 8Z"
        fill="white"
      />
    </svg>
  );
};

export default ArrowRightRedirect;
