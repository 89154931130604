import classNames from 'classnames';

const ArrowDownloadIcon = (props: React.SVGProps<SVGSVGElement>) => {
  const { className = '', ...attributes } = props;
  const classes = classNames('arrow-downloads-icon', className);

  return (
    <svg
      width="15.227"
      height="16.017"
      viewBox="0 0 15.227 16.017"
      className={classes}
      {...attributes}
    >
      <g
        id="Group_116"
        data-name="Group 116"
        transform="translate(14.414) rotate(90)"
      >
        <path
          id="Path_558"
          data-name="Path 558"
          d="M0,0,6.8,6.8,0,13.6"
          transform="translate(7.59)"
          fill="none"
          stroke="#fff"
          strokeMiterlimit="10"
          strokeWidth="2.3"
          fillRule="evenodd"
        />
        <line
          id="Line_63"
          data-name="Line 63"
          x2="13.851"
          transform="translate(0 6.8)"
          fill="none"
          stroke="#fff"
          strokeMiterlimit="10"
          strokeWidth="2.3"
        />
      </g>
    </svg>
  );
};

export default ArrowDownloadIcon;
