import classNames from 'classnames';

const CloudIcon = (props: React.SVGProps<SVGSVGElement>) => {
  const { className = '', ...attributes } = props;
  const classes = classNames('cloud-icon', className);

  return (
    <svg
      width="78"
      height="78"
      viewBox="0 0 78 78"
      fill="none"
      className={classes}
      {...attributes}
    >
      <mask
        id="mask0_2145_403"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="78"
        height="78"
      >
        <path
          d="M0.496094 0.499031H77.4961V77.499H0.496094V0.499031Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_2145_403)">
        <path
          d="M19.8529 16.29H7.41406V66.2197H70.5781V16.29H58.1393"
          stroke="#00B3FF"
          strokeWidth="2.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.9492 75.2432H57.043"
          stroke="#00B3FF"
          strokeWidth="2.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M34.4844 66.2197L29.9727 75.2432"
          stroke="#00B3FF"
          strokeWidth="2.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M48.0195 75.2432L43.5078 66.2197"
          stroke="#00B3FF"
          strokeWidth="2.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.41406 57.1963H70.5781"
          stroke="#00B3FF"
          strokeWidth="2.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M38.9961 43.3604C27.8006 43.3604 18.6934 34.2531 18.6934 23.0576C18.6934 11.8621 27.8006 2.75488 38.9961 2.75488C50.1916 2.75488 59.2988 11.8621 59.2988 23.0576C59.2988 34.2531 50.1916 43.3604 38.9961 43.3604Z"
          stroke="#00B3FF"
          strokeWidth="2.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M34.4844 36.5928V9.52246"
          stroke="#00B3FF"
          strokeWidth="2.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M29.9727 14.0342H43.5078C45.9995 14.0342 48.0195 16.0541 48.0195 18.5459C48.0195 21.0377 45.9995 23.0576 43.5078 23.0576"
          stroke="#00B3FF"
          strokeWidth="2.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M34.4844 23.0576H43.5078C45.9995 23.0576 48.0195 25.0775 48.0195 27.5693C48.0195 30.0612 45.9995 32.0811 43.5078 32.0811H29.9727"
          stroke="#00B3FF"
          strokeWidth="2.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M43.5078 36.5928V32.0811"
          stroke="#00B3FF"
          strokeWidth="2.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M43.5078 14.0342V9.52246"
          stroke="#00B3FF"
          strokeWidth="2.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export default CloudIcon;
