import Card from 'components/Card';
import { useTranslation } from 'next-i18next';
import { getDashboardUrl } from 'services/EnvProvider';

const FooterDiscord = () => {
  const { t } = useTranslation();

  const dashboardUrl = getDashboardUrl();

  return (
    <Card
      icon="socials/DiscordLogo"
      className="footer-discord card--small"
      header={t('footerDiscord.header')}
      description={t('footerDiscord.description')}
    >
      <a
        href={`${dashboardUrl}/discord`}
        className="footer-discord__link btn btn-type-blue"
        target="_blank"
        rel="noopener noreferrer"
      >
        {t('page.discordLink')}
      </a>
    </Card>
  );
};

export default FooterDiscord;
