import classNames from 'classnames';

const BtcMountainIcon = (props: React.SVGProps<SVGSVGElement>) => {
  const { className = '', ...attributes } = props;
  const classes = classNames('btc-mountain-icon', className);

  return (
    <svg
      width="31"
      height="30"
      viewBox="0 0 31 30"
      fill="none"
      className={classes}
      {...attributes}
    >
      <path
        d="M3.77148 1.55633C3.77148 1.55633 5.50677 -0.0219723 8.74193 3.21313C11.9771 6.44823 10.3987 8.18358 10.3987 8.18358"
        stroke="#00B3FF"
        strokeWidth="2.34309"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.11328 9.84043L10.3976 1.55615"
        stroke="#00B3FF"
        strokeWidth="2.34309"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.1562 21.799H17.8424M17.8424 21.799C19.1365 21.799 20.1855 20.7499 20.1855 19.4559C20.1855 18.1619 19.1365 17.1128 17.8424 17.1128H13.1562V26.4851H17.8424C19.1365 26.4851 20.1855 25.4361 20.1855 24.1421C20.1855 22.848 19.1365 21.799 17.8424 21.799Z"
        stroke="#00B3FF"
        strokeWidth="2.34309"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.5 26.4854V28.8284"
        stroke="#00B3FF"
        strokeWidth="2.34309"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.5 14.7695V17.1126"
        stroke="#00B3FF"
        strokeWidth="2.34309"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.8125 17.1128H13.1556"
        stroke="#00B3FF"
        strokeWidth="2.34309"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.8125 26.4854H13.1556"
        stroke="#00B3FF"
        strokeWidth="2.34309"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.8723 28.8283H25.8096C27.7506 28.8283 29.3242 27.2548 29.3242 25.3137C29.3242 24.7078 29.1709 24.1377 28.9009 23.6401L19.904 6.62483C19.5074 5.89384 18.7332 5.39746 17.8431 5.39746C17.0091 5.39746 16.277 5.8331 15.8617 6.48928L12.7952 11.3349C12.3799 11.991 11.6478 12.4267 10.8138 12.4267C9.04467 12.4267 7.50444 13.4071 6.70692 14.8542L2.11025 23.6197C1.83335 24.1221 1.67578 24.6995 1.67578 25.3137C1.67578 27.2548 3.24934 28.8283 5.19041 28.8283H6.12765"
        stroke="#00B3FF"
        strokeWidth="2.34309"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default BtcMountainIcon;
