import Link from 'next/link';
// import { FacebookLogo, TwitterLogo } from 'images/svg';
import { getFullUrl } from 'utils/Url';
import { useTranslation } from 'next-i18next';
import { getPublicApiUrl } from 'services/EnvProvider';
import { isSimpleMiningUrl } from 'utils/isSimpleMiningUrl';

const Footnotes = () => {
  const apiUrl = getPublicApiUrl();

  const { t } = useTranslation();

  const footerLinks = [
    {
      name: t('footnotes.privacy'),
      path: '/privacy-policy',
      isVisible: true,
    },
    {
      name: t('footnotes.changelog'),
      path: '/changelog',
      isVisible: true,
    },
    { name: t('navigation.support'), path: '/support', isVisible: true },
    {
      name: t('footnotes.gpuCalculator'),
      path: '/mining/gpu-mining-calculator',
      isVisible: isSimpleMiningUrl,
    },
    {
      name: t('footnotes.apiLink'),
      path: `${apiUrl}/docs`,
      isVisible: true,
    },
  ];

  return (
    <nav className="footnotes">
      <div className="footnotes__links-wrapper">
        <ul className="footnotes__links-list">
          {footerLinks.map(
            (link, index) =>
              link.isVisible && (
                <li key={index}>
                  {link.path?.startsWith('/') ? (
                    <Link href={getFullUrl(link.path)} legacyBehavior>
                      <a className="link-type-underline footnotes__link">
                        {link.name}
                      </a>
                    </Link>
                  ) : (
                    <a
                      href={link.path}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="link-type-underline footnotes__link"
                    >
                      {link.name}
                    </a>
                  )}
                </li>
              ),
          )}
        </ul>
        {/* <div className="footnotes__socials">
          <a
            href="https://www.facebook.com/simplemining.net"
            target="_blank"
            rel="noopener noreferrer"
            title="Facebook"
          >
            <FacebookLogo />
          </a>
          <a
            href="https://twitter.com/SimpleMiningNet"
            target="_blank"
            rel="noopener noreferrer"
            title="Twitter"
          >
            <TwitterLogo />
          </a> 
        </div>*/}
        <p className="footnotes__copyright">
          {`Copyright © ${new Date().getFullYear()} StupidThings.net Sp. z o.o. Sp. k. All rights reserved.`}
        </p>
      </div>
    </nav>
  );
};

export default Footnotes;

{
  /* <li key={index}>
{link.path?.startsWith('/') ? (
  <Link href={getFullUrl(link.path)} legacyBehavior>
    <a className="link-type-underline footnotes__link">
      {link.name}
    </a>
  </Link>
) : (
  <a
    href={link.path}
    className="link-type-underline footnotes__link"
  >
    {link.name}
  </a>
)}
</li> */
}
