import classNames from 'classnames';

const WiFiIcon = (props: React.SVGProps<SVGSVGElement>) => {
  const { className = '', ...attributes } = props;
  const classes = classNames('wifi-icon', className);

  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      className={classes}
      {...attributes}
    >
      <circle
        cx="32"
        cy="32"
        r="31.4286"
        fill="url(#paint0_linear_2145_714)"
        fillOpacity="0.5"
        stroke="#222332"
        strokeWidth="1.14286"
      />
      <path
        d="M34.5432 26.2049C33.1411 24.8028 30.8597 24.8028 29.4576 26.2049C28.7783 26.8841 28.4043 27.7871 28.4043 28.7477C28.4043 29.7082 28.7783 30.6112 29.4576 31.2905C29.9235 31.7563 30.4867 32.0665 31.0822 32.2228V44.9344C31.0822 45.4414 31.4933 45.8525 32.0004 45.8525C32.5074 45.8525 32.9185 45.4414 32.9185 44.9344V32.2228C33.5141 32.0665 34.0773 31.7564 34.5432 31.2905C35.2224 30.6113 35.5964 29.7083 35.5964 28.7477C35.5964 27.7872 35.2224 26.8842 34.5432 26.2049ZM33.2447 29.992C32.5586 30.6781 31.4422 30.6782 30.7561 29.992C30.4237 29.6596 30.2407 29.2178 30.2407 28.7477C30.2407 28.2777 30.4238 27.8358 30.7561 27.5034C31.0991 27.1604 31.5498 26.9889 32.0004 26.9889C32.451 26.9889 32.9017 27.1604 33.2447 27.5034C33.5771 27.8358 33.7601 28.2777 33.7601 28.7477C33.7601 29.2178 33.5771 29.6597 33.2447 29.992Z"
        fill="#00B3FF"
      />
      <path
        d="M38.7686 22.3705C38.4101 22.0119 37.8288 22.0119 37.4701 22.3705C37.1115 22.729 37.1115 23.3104 37.4701 23.669C40.2712 26.4701 40.2712 31.0279 37.4701 33.8289C37.1115 34.1874 37.1115 34.7688 37.4701 35.1274C37.6494 35.3067 37.8844 35.3963 38.1193 35.3963C38.3542 35.3963 38.5893 35.3067 38.7686 35.1274C42.2856 31.6103 42.2857 25.8876 38.7686 22.3705Z"
        fill="#00B3FF"
      />
      <path
        d="M42.7198 18.4154C42.3612 18.0569 41.7799 18.0569 41.4213 18.4154C41.0627 18.7739 41.0627 19.3553 41.4213 19.7139C46.4028 24.6953 46.4028 32.8008 41.4213 37.7823C41.0627 38.1409 41.0627 38.7222 41.4213 39.0808C41.6006 39.2601 41.8355 39.3498 42.0705 39.3498C42.3055 39.3498 42.5405 39.2601 42.7197 39.0808C45.4797 36.3208 46.9997 32.6513 46.9997 28.748C46.9997 24.8448 45.4797 21.1753 42.7198 18.4154Z"
        fill="#00B3FF"
      />
      <path
        d="M26.5319 23.669C26.8906 23.3104 26.8906 22.7291 26.532 22.3705C26.1735 22.0119 25.5921 22.0119 25.2335 22.3705C21.7164 25.8876 21.7165 31.6103 25.2335 35.1273C25.4128 35.3066 25.6478 35.3962 25.8827 35.3962C26.1177 35.3962 26.3527 35.3066 26.5319 35.1273C26.8905 34.7687 26.8905 34.1874 26.5319 33.8288C23.7309 31.0277 23.7309 26.47 26.5319 23.669Z"
        fill="#00B3FF"
      />
      <path
        d="M22.5784 19.7139C22.937 19.3553 22.937 18.774 22.5784 18.4154C22.2199 18.0569 21.6385 18.0569 21.2799 18.4154C18.52 21.1753 17 24.8449 17 28.748C17 32.6511 18.52 36.3207 21.28 39.0806C21.4593 39.2599 21.6942 39.3496 21.9292 39.3496C22.1641 39.3496 22.3992 39.2599 22.5784 39.0806C22.937 38.7221 22.937 38.1407 22.5784 37.7821C17.597 32.8007 17.597 24.6953 22.5784 19.7139Z"
        fill="#00B3FF"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2145_714"
          x1="32"
          y1="0"
          x2="32"
          y2="64"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#222332" />
          <stop offset="1" stopColor="#0C101C" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default WiFiIcon;
