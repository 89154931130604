import classNames from 'classnames';

const ManageIcon = (props: React.SVGProps<SVGSVGElement>) => {
  const { className = '', ...attributes } = props;
  const classes = classNames('manage-icon', className);

  return (
    <svg
      width="97.371"
      height="89.251"
      viewBox="0 0 97.371 89.251"
      fill="none"
      className={classes}
      {...attributes}
    >
      <path
        id="Union_19"
        data-name="Union 19"
        d="M-443,6653.252V6564h81.133v42.6h-4v-26.313H-439v68.971h26.417v4Zm77.134-76.97V6568H-439v8.282Zm-19.849,72.481-4.4,2.441-1.944-3.5,4.375-2.43a9.023,9.023,0,0,1-.349-2.5,8.728,8.728,0,0,1,2.288-5.953,7.716,7.716,0,0,1,5.712-2.548,7.716,7.716,0,0,1,5.712,2.548,8.432,8.432,0,0,1,.581.7l14.988-8.327,1.942,3.5-15.355,8.531a9.1,9.1,0,0,1,.132,1.549,8.744,8.744,0,0,1-2.288,5.953,7.721,7.721,0,0,1-5.712,2.546A7.717,7.717,0,0,1-385.715,6648.763Zm1.685-5.98a4.277,4.277,0,0,0,4,4.5,4.276,4.276,0,0,0,4-4.5,4.275,4.275,0,0,0-4-4.5A4.276,4.276,0,0,0-384.03,6642.783Zm28.282,2.351v-4h6.055a30.49,30.49,0,0,0-13.479-23.329l-3.023,5.234-3.463-2,3.025-5.24a30.241,30.241,0,0,0-11.4-3.073v5.557h-4v-5.567a30.253,30.253,0,0,0-11.548,3.083l3.026,5.24-3.465,2-3.022-5.234a30.732,30.732,0,0,0-8.4,8.4l5.235,3.023-2,3.463-5.24-3.025a30.25,30.25,0,0,0-3.077,11.472h6.055v4h-10.12v-2a34.263,34.263,0,0,1,2.71-13.421,34.355,34.355,0,0,1,7.389-10.958,34.349,34.349,0,0,1,10.959-7.387,34.256,34.256,0,0,1,13.42-2.71,34.265,34.265,0,0,1,13.421,2.71,34.349,34.349,0,0,1,10.959,7.387,34.35,34.35,0,0,1,7.387,10.958,34.263,34.263,0,0,1,2.71,13.421v2Zm-77.283-14.852v-4h11v4Zm0-10v-4h19v4Zm0-12v-22h28v22Zm4-4h20v-14h-20Zm30-4v-4h27v4Zm0-10v-4h27v4Zm-27-17v-4h4v4Zm-9,0v-4h4v4Z"
        transform="translate(443 -6564)"
        fill="#00B3FF"
      />
    </svg>
  );
};

export default ManageIcon;
