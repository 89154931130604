import { useContext, useEffect, useState } from 'react';
import { Context } from 'context/GlobalContext';
import useScrollListener from 'hooks/useScroll';
import classNames from 'classnames';
import Link from 'next/link';
import NavigationBarToggle from 'components/Layout/NavigationBar/NavigationBarToggle';
import NavigationBarMenu from './NavigationBarMenu';
import { SmLogo, SmBrandName, SpBrandName, SpLogo } from 'images/svg';
import { isSimpleMiningUrl, homePageUrl } from 'utils/isSimpleMiningUrl';

const NavigationBar = () => {
  const { isScrollActive, isScrollOnStartPosition } = useScrollListener();

  const { isCurtainActive, setIsCurtainActive } = useContext(Context);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isCurtainStyleActive, setIsCurtainStyleActive] =
    useState<boolean>(false);
  // const [wrapperStyle, setWrapperStyle] = useState<any>({});

  const navigationClasses = classNames('navigation-bar', {
    'navigation-bar--on-start': isScrollOnStartPosition,
    'navigation-bar--hidden': !isOpen && !isCurtainActive && isScrollActive,
    'navigation-bar--curtain-active': isCurtainStyleActive,
  });

  // useEffect(() => {
  //   const scrollBarWidth = window.innerWidth - document.body.clientWidth;
  //   const navigationWrapper =
  //     document.querySelector<HTMLDivElement>('.navigation-bar')!;

  //   const getPaddingValue = () => {
  //     return parseFloat(
  //       window
  //         .getComputedStyle(navigationWrapper, null)
  //         .getPropertyValue('padding-right'),
  //     );
  //   };

  //   if (isCurtainActive) {
  //     setWrapperStyle({
  //       paddingRight: `${getPaddingValue() + scrollBarWidth}px`,
  //     });

  //     return;
  //   }

  //   setWrapperStyle({});
  // }, [isCurtainActive]);

  const onToggleClick = () => {
    setIsOpen(prev => !prev);
    setIsCurtainActive(prev => !prev);
  };

  const onCurtainClick = () => {
    setIsOpen(false);
    setIsCurtainActive(false);
  };

  useEffect(() => {
    setIsCurtainStyleActive(isCurtainActive);
  }, [isCurtainActive]);

  return (
    // <nav className={navigationClasses} style={wrapperStyle}>
    <nav className={navigationClasses}>
      <NavigationBarToggle isOpen={isOpen} onClick={onToggleClick} />
      <Link href={homePageUrl()} legacyBehavior>
        {isSimpleMiningUrl ? (
          <a
            className="navigation-bar__logo"
            aria-label="simplemining home page"
          >
            <SmLogo className="navigation-bar__logo-img" />
            <SmBrandName className="navigation-bar__logo-brand-name" />
          </a>
        ) : (
          <a
            className="navigation-bar__logo-simplepod"
            aria-label="simplepod home page"
          >
            <SpLogo className="navigation-bar__logo-img" />
            <SpBrandName className="navigation-bar__logo-brand-name" />
          </a>
        )}
      </Link>
      <NavigationBarMenu isOpen={isOpen} setIsOpen={setIsOpen} />
      {isOpen && (
        <div
          className="navigation-bar__mobile-curtain"
          onClick={onCurtainClick}
        />
      )}
    </nav>
  );
};

export default NavigationBar;
