import classNames from 'classnames';

const FlashDriveIcon = (props: React.SVGProps<SVGSVGElement>) => {
  const { className = '', ...attributes } = props;
  const classes = classNames('flash-drive-icon', className);

  return (
    <svg
      width="100%"
      viewBox="0 0 180 180"
      fill="none"
      className={classes}
      {...attributes}
    >
      <circle
        cx="90"
        cy="90"
        r="89.1667"
        fill="url(#paint0_linear_2145_248)"
        stroke="url(#paint1_linear_2145_248)"
        strokeWidth="1.66667"
      />
      <path
        d="M66.4508 113.326C71.8123 118.687 80.505 118.687 85.8664 113.326L92.3384 106.854C97.6999 101.492 97.6999 92.7996 92.3384 87.4381C86.9769 82.0766 78.2843 82.0766 72.9228 87.4381L66.4508 93.9101C61.0893 99.2716 61.0893 107.964 66.4508 113.326Z"
        stroke="#00B3FF"
        strokeWidth="2.66667"
        strokeMiterlimit="10"
      />
      <path
        d="M65.1172 95.4585C69.9485 96.6995 75.1879 95.9387 79.5191 93.1764C81.9281 91.7328 84.3526 92.3732 85.8626 93.9153C87.4183 95.5039 88.0036 98.0495 86.6016 100.259C83.8391 104.59 83.0785 109.83 84.3195 114.661"
        stroke="#00B3FF"
        strokeWidth="2.66667"
        strokeMiterlimit="10"
      />
      <path
        d="M62.4375 104.024C64.5819 104.529 67.039 104.779 69.0319 104.983C70.5041 105.147 71.8767 105.807 72.924 106.854C73.9714 107.901 74.631 109.274 74.7946 110.746C75.0114 112.825 75.2408 115.174 75.7563 117.341"
        stroke="#00B3FF"
        strokeWidth="2.66667"
        strokeMiterlimit="10"
      />
      <path
        d="M116.712 95.4271L84.3532 63.0679L59.9831 87.438C51.0473 96.3738 51.0473 110.861 59.9831 119.797C68.9189 128.733 83.4065 128.733 92.3423 119.797L116.712 95.4271Z"
        stroke="#00B3FF"
        strokeWidth="2.66667"
        strokeMiterlimit="10"
      />
      <path
        d="M107.803 65.5024L102.047 71.2585"
        stroke="#00B3FF"
        strokeWidth="2.66667"
        strokeMiterlimit="10"
      />
      <path
        d="M108.523 77.7306L114.279 71.9746"
        stroke="#00B3FF"
        strokeWidth="2.66667"
        strokeMiterlimit="10"
      />
      <path
        d="M90.875 69.5933L106.246 54.2227L125.555 73.5318L110.184 88.9024"
        stroke="#00B3FF"
        strokeWidth="2.66667"
        strokeMiterlimit="10"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2145_248"
          x1="36.6667"
          y1="-7.77778"
          x2="134.444"
          y2="180"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#05638C" />
          <stop offset="0.589072" stopColor="#041922" />
          <stop offset="1" stopColor="#090C16" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2145_248"
          x1="43"
          y1="15.5"
          x2="146"
          y2="170"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1BBAFF" />
          <stop offset="0.440508" stopColor="#12182D" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default FlashDriveIcon;
