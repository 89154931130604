import classNames from 'classnames';

const FlagSpainIcon = (props: React.SVGProps<SVGSVGElement>) => {
  const { className = '', ...attributes } = props;
  const classes = classNames('flag-spain', className);

  return (
    <svg
      width="750"
      height="500"
      viewBox="0 0 750 500"
      className={classes}
      {...attributes}
    >
      <rect width="750" height="500" fill="#c60b1e" />
      <rect width="750" height="250" fill="#ffc400" y="125" />
    </svg>
  );
};

export default FlagSpainIcon;
