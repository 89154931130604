import classNames from 'classnames';

const TwitterLogo = (props: React.SVGProps<SVGSVGElement>) => {
  const { className = '', ...attributes } = props;
  const classes = classNames('twitter-logo', className);

  return (
    <svg
      viewBox="0 0 32 32"
      fill="none"
      width="32"
      height="32"
      className={classes}
      {...attributes}
    >
      <circle cx="16" cy="16" r="16" fill="#232432" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4945 10.165H9.22363L14.2874 16.8187L9.54757 22.4003H11.7375L15.3224 18.1788L18.5057 22.3615H22.7766L17.5657 15.5145L17.5749 15.5263L22.0616 10.2428H19.8716L16.5397 14.1664L13.4945 10.165ZM11.5811 11.3303H12.9107L20.4191 21.1962H19.0895L11.5811 11.3303Z"
        fill="white"
      />
    </svg>
  );
};

export default TwitterLogo;
