import classNames from 'classnames';

const ThumbsDownIcon = (props: React.SVGProps<SVGSVGElement>) => {
  const { className = '', ...attributes } = props;
  const classes = classNames('thumbs-down-icon', className);

  return (
    <svg
      width="16"
      height="13.999"
      viewBox="0 0 16 13.999"
      className={classes}
      {...attributes}
    >
      <path
        id="Path_31095"
        data-name="Path 31095"
        d="M3,41.048H1a1,1,0,0,1-1-.972V33.1a.976.976,0,0,1,1-.972H3A1,1,0,0,1,4,33.1v6.944A1.017,1.017,0,0,1,3,41.048Zm13-1.122a2.1,2.1,0,0,1-2.093,2.091H10.787a6.058,6.058,0,0,1,.463,1.853,2.148,2.148,0,0,1-2.189,2.149c-1.457,0-1.855-1.014-2.175-1.828C5.9,41.676,5,42.117,5,41.27a.75.75,0,0,1,1.218-.585c2.4,1.918,1.812,3.834,2.842,3.834a.657.657,0,0,0,.689-.65,6.453,6.453,0,0,0-.8-2.237.748.748,0,0,1-.1-.366.726.726,0,0,1,.75-.722h4.3a.636.636,0,0,0,.594-.618.591.591,0,0,0-.552-.585.75.75,0,0,1-.7-.748c0-.483.356-.5.356-.9,0-.793-1.095-.386-1.095-1.317,0-.351.2-.407.2-.7,0-.708-.93-.43-.93-1.27,0-.141.035-.186.035-.3a.593.593,0,0,0-.593-.592H9.58a3.751,3.751,0,0,0-2.234.744l-1.146.86a.75.75,0,1,1-.9-1.2l1.146-.86A5.261,5.261,0,0,1,9.581,32.02h1.642a2.1,2.1,0,0,1,2.087,1.944,2.075,2.075,0,0,1,.881,2,2.084,2.084,0,0,1,.856,2.216A2.126,2.126,0,0,1,16,39.926Z"
        transform="translate(0 -32.02)"
        fill="#fe1041"
      />
    </svg>
  );
};

export default ThumbsDownIcon;
