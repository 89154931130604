import classNames from 'classnames';

const ManualIcon = (props: React.SVGProps<SVGSVGElement>) => {
  const { className = '', ...attributes } = props;
  const classes = classNames('manual-icon', className);

  return (
    <svg
      width="100%"
      viewBox="0 0 68 77"
      fill="none"
      className={classes}
      {...attributes}
    >
      <path
        d="M50.0156 48.2002V61.209V70.2324H20.2383"
        stroke="white"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.19141 65.7207V7.80979C2.19141 4.74242 4.67796 2.25586 7.74533 2.25586H50.0156V12.332"
        stroke="white"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.2148 70.2324H6.70312"
        stroke="white"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M50.0156 70.2324H20.2383"
        stroke="white"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.2148 70.2324H6.70312C5.45789 70.2324 4.32996 69.7271 3.51334 68.9105C2.69672 68.0939 2.19141 66.9659 2.19141 65.7207C2.19141 63.2287 4.21115 61.209 6.70312 61.209H11.2148H20.2383H50.0156"
        stroke="white"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.2383 61.209V70.2324V74.7441L15.7266 71.7363L11.2148 74.7441V70.2324V61.209"
        stroke="white"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M65.8066 12.8584H33.623V47.8994H65.8066V12.8584Z"
        stroke="white"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M49.7148 30.8301V38.9512"
        stroke="white"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="49.7148" cy="21.8066" r="2.25586" fill="white" />
      <path
        d="M33.623 18.9492H14.2227V27.9727H33.623"
        stroke="white"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.2227 36.9961H33.623"
        stroke="white"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ManualIcon;
