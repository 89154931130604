import classNames from 'classnames';

const UpdateIcon = (props: React.SVGProps<SVGSVGElement>) => {
  const { className = '', ...attributes } = props;
  const classes = classNames('update-icon', className);

  return (
    <svg
      width="78"
      height="78"
      viewBox="0 0 78 78"
      fill="none"
      className={classes}
      {...attributes}
    >
      <mask
        id="mask0_2145_441"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="78"
        height="78"
      >
        <path
          d="M0.496094 0.499031H77.4961V77.499H0.496094V0.499031Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_2145_441)">
        <path
          d="M58.5467 38.999C58.5467 37.5666 58.383 36.1741 58.0906 34.8293L61.7146 30.2237L57.9548 23.7117C57.9548 23.7117 52.5 24.4976 52.1336 24.5504C50.0799 22.6816 47.6392 21.2335 44.9295 20.371L42.7557 14.9365H35.2362L33.0624 20.371C30.3527 21.2335 27.9122 22.6816 25.8583 24.5502L20.0371 23.7117L16.2773 30.2237C16.2773 30.2237 19.6503 34.5105 19.9013 34.8293C19.6089 36.1741 19.4452 37.5666 19.4452 38.999C19.4452 40.4313 19.6089 41.8238 19.9013 43.1686L16.2773 47.7742L20.0371 54.2862L25.8583 53.4477C27.912 55.3163 30.3527 56.7644 33.0624 57.6269L35.2362 63.0615H42.7557C42.7557 63.0615 44.7741 58.0153 44.9295 57.6269C47.6392 56.7644 50.0799 55.3163 52.1336 53.4477L57.9548 54.2862L61.7146 47.7742L58.0906 43.1686C58.383 41.8238 58.5467 40.4313 58.5467 38.999Z"
          stroke="#00B3FF"
          strokeWidth="2.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M28.4688 38.999C28.4688 33.1849 33.182 28.4717 38.9961 28.4717C44.8102 28.4717 49.5234 33.1849 49.5234 38.999C49.5234 44.8131 44.8102 49.5264 38.9961 49.5264C33.182 49.5264 28.4688 44.8131 28.4688 38.999Z"
          stroke="#00B3FF"
          strokeWidth="2.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.543 6.31801L20.8739 7.61062L19.5812 14.9414"
          stroke="#00B3FF"
          strokeWidth="2.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M64.4481 71.6802L57.1172 70.3876L58.4099 63.0566"
          stroke="#00B3FF"
          strokeWidth="2.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M30.7246 3.71439C33.3829 3.09358 36.1495 2.7546 38.997 2.7546C59.014 2.7546 75.2411 18.9817 75.2411 38.9989C75.2411 52.4115 67.9497 64.1137 57.1203 70.3813"
          stroke="#00B3FF"
          strokeWidth="2.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.8728 7.61642C10.0432 13.8841 2.75195 25.5864 2.75195 38.999C2.75195 59.0162 18.979 75.2432 38.9961 75.2432C41.8434 75.2432 44.6102 74.9042 47.2685 74.2834"
          stroke="#00B3FF"
          strokeWidth="2.4"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export default UpdateIcon;
