import classNames from 'classnames';

const FacebookLogo = (props: React.SVGProps<SVGSVGElement>) => {
  const { className = '', ...attributes } = props;
  const classes = classNames('facebook-logo', className);

  return (
    <svg
      viewBox="0 0 32 32"
      fill="none"
      width="32"
      height="32"
      className={classes}
      {...attributes}
    >
      <circle cx="16" cy="16" r="16" fill="#232432" />
      <path
        d="M16.8744 17.2599V22.7761H14.3405V17.2599H12.2354V15.0232H14.3405V14.2094C14.3405 11.1882 15.6026 9.59961 18.2729 9.59961C19.0916 9.59961 19.2963 9.73118 19.7446 9.83838V12.0507C19.2427 11.963 19.1013 11.9143 18.5799 11.9143C17.9611 11.9143 17.6297 12.0897 17.3276 12.4357C17.0255 12.7816 16.8744 13.381 16.8744 14.2387V15.0281H19.7446L18.9746 17.2648H16.8744V17.2599Z"
        fill="white"
      />
    </svg>
  );
};

export default FacebookLogo;
