import classNames from 'classnames';

const FlagRussiaIcon = (props: React.SVGProps<SVGSVGElement>) => {
  const { className = '', ...attributes } = props;
  const classes = classNames('flag-russia', className);

  return (
    <svg width="100%" viewBox="0 0 9 6" className={classes} {...attributes}>
      <path fill="#fff" d="M0 0h9v3H0z" />
      <path fill="#DA291C" d="M0 3h9v3H0z" />
      <path fill="#0032A0" d="M0 2h9v2H0z" />
    </svg>
  );
};

export default FlagRussiaIcon;
