import classNames from 'classnames';

const BtcHeadphonesIcon = (props: React.SVGProps<SVGSVGElement>) => {
  const { className = '', ...attributes } = props;
  const classes = classNames('btc-headphones-icon', className);

  return (
    <svg
      width="31"
      height="30"
      viewBox="0 0 31 30"
      fill="none"
      className={classes}
      {...attributes}
    >
      <g clipPath="url(#clip0_2145_555)">
        <path
          d="M13.1562 19.4531V10.0781H17.8438C19.1381 10.0781 20.1875 11.1275 20.1875 12.4219C20.1875 13.7163 19.1381 14.7656 17.8438 14.7656"
          stroke="#00B3FF"
          strokeWidth="2.34375"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.8125 19.4531H17.8438C19.1381 19.4531 20.1875 18.4038 20.1875 17.1094C20.1875 15.815 19.1381 14.7656 17.8438 14.7656H13.1562"
          stroke="#00B3FF"
          strokeWidth="2.34375"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.5 7.73438V10.0781"
          stroke="#00B3FF"
          strokeWidth="2.34375"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.5 21.7969V19.4531"
          stroke="#00B3FF"
          strokeWidth="2.34375"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.1562 10.0781H10.8125"
          stroke="#00B3FF"
          strokeWidth="2.34375"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.01562 24.1406C2.72123 24.1406 1.67188 23.0913 1.67188 21.7969V17.1094C1.67188 15.815 2.72123 14.7656 4.01562 14.7656C5.31002 14.7656 6.35938 15.815 6.35938 17.1094V21.7969C6.35938 23.0913 5.31002 24.1406 4.01562 24.1406Z"
          stroke="#00B3FF"
          strokeWidth="2.34375"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M26.9844 24.1406C25.69 24.1406 24.6406 23.0913 24.6406 21.7969V17.1094C24.6406 15.815 25.69 14.7656 26.9844 14.7656C28.2788 14.7656 29.3281 15.815 29.3281 17.1094V21.7969C29.3281 23.0913 28.2788 24.1406 26.9844 24.1406Z"
          stroke="#00B3FF"
          strokeWidth="2.34375"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.5 26.4844C15.5 27.7788 16.5494 28.8281 17.8438 28.8281H24.6406C25.935 28.8281 26.9844 27.7788 26.9844 26.4844V24.1406"
          stroke="#00B3FF"
          strokeWidth="2.34375"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.25 10.5507C5.33434 5.19979 9.82994 1.17188 15.5008 1.17188C21.1716 1.17188 25.6672 5.19979 26.7515 10.5507"
          stroke="#00B3FF"
          strokeWidth="2.34375"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2145_555">
          <rect
            width="30"
            height="30"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BtcHeadphonesIcon;
