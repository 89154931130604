import classNames from 'classnames';

const SupportIcon = (props: React.SVGProps<SVGSVGElement>) => {
  const { className = '', ...attributes } = props;
  const classes = classNames('support-icon', className);

  return (
    <svg
      width="78"
      height="78"
      viewBox="0 0 78 78"
      fill="none"
      className={classes}
      {...attributes}
    >
      <path
        d="M48.8328 43.541V46.7036C48.8328 52.6618 43.9579 57.537 37.9994 57.537C32.0411 57.537 27.166 52.6619 27.166 46.7036V44.3491"
        stroke="#00B3FF"
        strokeWidth="2.4"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.3216 44.3604L11.7425 50.258C7.87146 51.7234 5.55078 55.3813 5.55078 59.5211V75.2442H70.4479V59.5211C70.4479 55.3788 68.1249 51.7322 64.2562 50.258L48.6771 44.3213"
        stroke="#00B3FF"
        strokeWidth="2.4"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M54.8457 22.7917V30.5184C54.8457 39.7835 47.2651 47.3641 38 47.3641C28.7349 47.3641 21.1543 39.7835 21.1543 30.5184V18.5469"
        stroke="#00B3FF"
        strokeWidth="2.4"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M41.5039 37.168H54.8864"
        stroke="#00B3FF"
        strokeWidth="2.4"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M54.8438 21.8735H59.6233C62.252 21.8735 64.4027 24.0243 64.4027 26.6529V32.3882C64.4027 35.0169 62.252 37.1677 59.6233 37.1677H54.8438V21.8735Z"
        stroke="#00B3FF"
        strokeWidth="2.4"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.7832 2.75586H36.5653C39.2821 2.75586 41.7092 4.05659 43.2565 6.06551V4.78373C43.2565 4.20307 43.4876 3.70317 43.9299 3.32689C44.3724 2.95077 44.9028 2.80293 45.4759 2.89617C50.6199 3.73325 54.5679 8.21504 54.5679 13.5892V19.6432C45.9118 19.6432 37.2556 19.6432 28.5995 19.6432C20.439 19.6432 13.7832 12.9874 13.7832 4.82689V2.75586Z"
        stroke="#00B3FF"
        strokeWidth="2.4"
        strokeMiterlimit="22.9256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SupportIcon;
