export const getWithDomain = (path: string) => {
  if (getHost().includes('simplepod.ai')) {
    return path.replace('simplemining.net', 'simplepod.ai');
  }

  return path;
};

export const getPublicApiUrl = () => getDashboardUrl().replace('dash', 'api');

export const getApiUrl = () => getWithDomain(process.env.API_URL as string);

export const getApiHost = () => getWithDomain(process.env.API_HOST as string);

export const getDashboardUrl = () =>
  getWithDomain(process.env.NEXT_PUBLIC_DASHBOARD_URL as string);

export const getLandingPageUrl = () =>
  getWithDomain(process.env.NEXT_PUBLIC_LANDING_PAGE_URL as string);

export const getHost = () => process.env.NEXT_PUBLIC_HOST ?? 'simplemining.net';
