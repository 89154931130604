import {
  createContext,
  useState,
  ReactNode,
  useEffect,
  Dispatch,
  SetStateAction,
} from 'react';
import Cookies from 'universal-cookie';

interface Props {
  children: ReactNode;
}

interface IContext {
  isCurtainActive: boolean;
  isUserLoggedIn: boolean;
  setIsCurtainActive: Dispatch<SetStateAction<boolean>>;
}

const initialContext = {
  isCurtainActive: false,
  isUserLoggedIn: false,
  setIsCurtainActive: prev => prev,
};

export const Context = createContext<IContext>(initialContext);

const GlobalContext = (props: Props) => {
  const { children } = props;

  const [isCurtainActive, setIsCurtainActive] = useState<boolean>(false);
  const [isUserLoggedIn, setIsUserLoggedIn] = useState<boolean>(false);

  useEffect(() => {
    setIsUserLoggedIn(!!new Cookies().get('sm_user_token'));
  }, []);

  return (
    <Context.Provider
      value={{
        isCurtainActive,
        isUserLoggedIn,
        setIsCurtainActive,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default GlobalContext;
